import { createRef, Component } from "react";
import { Portal } from "react-portal";
import { EditorView } from "@knowt/editor/pm/view";
import styled from "styled-components";
import { Group } from "./types";
import { FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import clsx from "clsx";
import { SLASH_MENU_BUTTON_CLASS_NAME } from "@/components/FullPageEditor/SlashMenu/constants";

type MenuPosition = {
    left: number;
    top?: number;
    bottom?: number;
    isAbove?: boolean;
};

const DEFAULT_MENU_POSITION: MenuPosition = {
    left: -1000,
    top: 0,
    bottom: undefined,
};

const DEFAULT_MENU_MAX_HEIGHT = 250;
const SAFE_MARGIN_Y = 63; // our navbar height is about 55px
const SAFE_MARGIN_X = 10;
const ITEM_HEIGHT = 40;

const DEFAULT_STATE = {
    activeIndex: 0,
    menuPosition: DEFAULT_MENU_POSITION,
    menuMaxHeight: DEFAULT_MENU_MAX_HEIGHT,
};

type SlashMenuProps = {
    isActive: boolean;
    view: EditorView;
    // TODO: remove search prop, as the items passed are already filtered
    search: string | null;
    groups: Group[];
    command: (props: Group["items"][0]) => void;
    isDarkMode: boolean;
    clientRect: (() => DOMRect | null) | null;
};

type SlashMenuState = {
    menuPosition: MenuPosition;
    menuMaxHeight: number;
    activeIndex: number;
};

class KnowtCommandMenu extends Component<SlashMenuProps, SlashMenuState> {
    menuRef = createRef<HTMLDivElement>();
    listRef = createRef<HTMLOListElement>();

    itemsRef: HTMLElement[] = [];

    state: SlashMenuState = DEFAULT_STATE;

    constructor(props: SlashMenuProps) {
        super(props);
    }

    componentDidUpdate(prevProps: SlashMenuProps) {
        const menuHasOpened = this.props.isActive && !prevProps.isActive;
        const menuHasClosed = !this.props.isActive && prevProps.isActive;
        const searchHasChanged = this.props.search !== prevProps.search;

        if (menuHasOpened) {
            this.setState({ activeIndex: 0, menuPosition: this.calculateMenuInitialPosition() }, () => {
                this.setState({ menuMaxHeight: this.calculateMenuMaxHeight() });
            });
        }

        if (menuHasClosed) {
            this.setState(DEFAULT_STATE);
        }

        if (searchHasChanged) {
            this.setState({ activeIndex: 0, menuMaxHeight: this.calculateMenuMaxHeight() });
        }
    }

    calculateMenuMaxHeight(menuPosition?: MenuPosition) {
        const menuMaxHeight = this.calculateMenuTheoreticalHeight();
        const menuSpace = menuMaxHeight + SAFE_MARGIN_Y;

        const { top = 0, bottom = 0, isAbove } = menuPosition ?? this.state.menuPosition;

        // how much of the search menu is non-visible
        // negative number indicates that its fully visible
        const menuClippedHeight =
            (isAbove ? top - window.scrollY : bottom + window.scrollY) + menuSpace - window.innerHeight;

        return menuMaxHeight - menuClippedHeight;
    }

    calculateMenuTheoreticalHeight(): number {
        const itemsCount = this.props.groups.map(({ items }) => items).flat().length;
        const itemsHeight = itemsCount * ITEM_HEIGHT;

        const groupsCount = this.props.groups.length;
        const groupsHeight = groupsCount * 12; // 12px is a rough estimate

        const blockPadding = 28; // 28px is a rough estimate

        return itemsHeight + groupsHeight + blockPadding;
    }

    calculateMenuInitialPosition(): MenuPosition {
        if (!this.menuRef.current || !this.props.isActive || !this.props.clientRect) {
            return DEFAULT_MENU_POSITION;
        }

        const clientRect = this.props.clientRect();
        if (!clientRect) return DEFAULT_MENU_POSITION;

        const { top, bottom, left } = clientRect;

        const { offsetHeight: menuHeight, offsetWidth: menuWidth } = this.menuRef.current;

        // to not overflow the screen
        const adjustedLeft = Math.min(left, window.innerWidth - menuWidth - SAFE_MARGIN_X);

        const startPos = this.props.view.coordsAtPos(this.props.view.state.selection.from);

        // TODO: pick a descriptive name for this long variable
        if (startPos.top + menuHeight + SAFE_MARGIN_Y < window.innerHeight) {
            return {
                left: adjustedLeft,
                top: bottom + window.scrollY,
                bottom: undefined,
                isAbove: true,
            };
        } else {
            return {
                left: adjustedLeft,
                top: undefined,
                bottom: window.innerHeight - top - window.scrollY,
                isAbove: false,
            };
        }
    }

    handleKeyDown = (e: KeyboardEvent): boolean => {
        if (!this.props.isActive) {
            return false;
        }

        const currentIndex = this.state.activeIndex ?? 0;

        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            this.selectItem(currentIndex);
            return true;
        }

        const isUpKey = e.key === "ArrowUp";
        const isDownKey = e.key === "ArrowDown";

        if (isUpKey || isDownKey) {
            e.preventDefault();
            e.stopPropagation();

            const newIndex = isUpKey
                ? currentIndex - 1 < 0
                    ? this.items.length - 1
                    : currentIndex - 1
                : currentIndex + 1 === this.items.length
                ? 0
                : currentIndex + 1;

            this.setState({ activeIndex: newIndex });
            this.itemsRef[newIndex].scrollIntoView({ block: "nearest" });

            return true;
        }

        return false;
    };

    get items(): Group["items"] {
        return this.props.groups.map(({ items }) => items).flat();
    }

    selectItem(index: number) {
        const item = this.items[index];
        if (!item) return; // empty menu for instance

        this.props.command(item);
    }

    renderItems() {
        let currentIndex = 0;

        return this.props.groups.map(group => {
            return (
                <Group key={group.name}>
                    <GroupName>{group.name}</GroupName>
                    {group.items.map(item => {
                        const globalItemIdx = currentIndex++;
                        const isSelected = globalItemIdx === this.state.activeIndex;

                        const Icon = item.icon;

                        return (
                            <CommandItem
                                key={item.title}
                                className={clsx(SLASH_MENU_BUTTON_CLASS_NAME, "strippedBtn")}
                                ref={node => {
                                    if (node) this.itemsRef[globalItemIdx] = node;
                                }}
                                style={{
                                    backgroundColor: isSelected
                                        ? this.props.isDarkMode
                                            ? "rgba(255, 255, 255, 0.1)"
                                            : "rgba(0, 0, 0, 0.05)"
                                        : undefined,
                                }}
                                onClick={() => this.selectItem(globalItemIdx)}>
                                <FlexRowAlignCenter style={{ columnGap: spacing.XS_2 }}>
                                    <Icon />
                                    <div>{item.title}</div>
                                </FlexRowAlignCenter>
                            </CommandItem>
                        );
                    })}
                </Group>
            );
        });
    }

    render() {
        const { isActive } = this.props;
        if (!isActive) return null;

        return (
            <Portal>
                <Wrapper
                    ref={this.menuRef}
                    style={{ maxHeight: this.state.menuMaxHeight }}
                    {...this.state.menuPosition}>
                    {this.props.groups.length === 0 ? (
                        <Empty>No results</Empty>
                    ) : (
                        <List ref={this.listRef}>{this.renderItems()}</List>
                    )}
                </Wrapper>
            </Portal>
        );
    }
}

// export default KnowtCommandMenu;

const Wrapper = styled.div<{
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    isAbove?: boolean;
}>`
    @keyframes scale-in-top {
        from {
            opacity: 0.5;
            transform: scaleY(0.9);
            transform-origin: top;
        }
        to {
            opacity: 1;
            transform: scaleY(1);
            transform-origin: top;
        }
    }

    @keyframes scale-in-bottom {
        from {
            opacity: 0.5;
            transform: scaleY(0.9);
            transform-origin: bottom;
        }
        to {
            opacity: 1;
            transform: scaleY(1);
            transform-origin: bottom;
        }
    }

    color: ${props => props.theme.text};
    font-family: ${props => props.theme.fontFamilyMono};
    position: absolute;
    z-index: 1500;
    ${props => props.top !== undefined && `top: ${props.top}px`};
    ${props => props.bottom !== undefined && `bottom: ${props.bottom}px`};
    ${({ left }) => left && `left: ${left}px;`}
    ${({ right }) => right && `right: ${right}px;`}
    background-color: var(--color-neutral-white);
    border-radius: 14px;
    box-shadow: rgb(0 0 0 / 8%) 0 0.4rem 1.6rem 0;
    box-sizing: border-box;
    white-space: nowrap;
    min-width: 28rem;
    overflow: hidden;
    overflow-y: auto;

    * {
    }

    &::-webkit-scrollbar {
        background: transparent;
        width: 4px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background-color: rgba(220, 220, 220, 0.6);
    }

    ${({ isAbove }) =>
        isAbove
            ? `
    animation: scale-in-top 0.32s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `
            : `
    animation: scale-in-bottom 0.32s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `} @media print {
        display: none;
    }
`;

const List = styled.ol`
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: left;
    height: 100%;
    row-gap: ${spacing.SM};
    padding: ${spacing.XS} 0;
    background-color: var(--color-neutral-white);
    margin: 0;
`;

const Group = styled.div`
    display: flex;
    flex-direction: column;
`;

const GroupName = styled.div`
    color: ${props => props.theme.placeholder};
    font-size: 1rem;
    font-weight: 500;
    margin-block: ${spacing.XS};
    padding-inline: ${spacing.SM};
`;

const CommandItem = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    cursor: pointer;
    height: ${ITEM_HEIGHT}px;
    padding-inline: ${spacing.SM};

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    [data-theme="dark"] &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const Empty = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.textSecondary};
    font-weight: 500;
    font-size: 1.4rem;
    height: ${ITEM_HEIGHT}px;
    padding-inline: ${spacing.SM};
    margin-block: 5px;
`;

export default KnowtCommandMenu;
