import React, { useRef } from "react";
import { LucideProps } from "lucide-react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

export type Item = {
    name: string;
    tooltip?: string;
    isActive: () => boolean;
    isDisabled: () => boolean;
    command: () => void;
    icon: (props: LucideProps) => React.ReactElement;
};

const ConditionalTooltip = ({
    text,
    disabled,
    children,
}: {
    text?: string;
    disabled: boolean;
    children: React.ReactElement;
}) => {
    if (disabled || !text) return children;

    return (
        <Tooltip title={text} TransitionComponent={Zoom}>
            {children}
        </Tooltip>
    );
};

const CommandItem = ({ item }: { item: Item }) => {
    const ref = useRef<HTMLButtonElement>(null);
    const Icon = item.icon;

    return (
        <ConditionalTooltip disabled={item.isDisabled()} text={item.tooltip}>
            <Button
                className="toolbar-selection-item-button"
                ref={ref}
                active={item.isActive()}
                disabled={item.isDisabled()}
                type="button"
                aria-pressed={item.isActive()}
                aria-describedby={item.name}
                onClick={() => item.command()}>
                {Icon ? <Icon /> : null}
            </Button>
        </ConditionalTooltip>
    );
};

export default CommandItem;

type ButtonProps = {
    active?: boolean;
    disabled?: boolean;
    showBackgroundOnActive?: boolean;
};

const Button = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    padding: 10px 3px;
    border: none;
    background: none;
    transition: opacity 85ms ease-in-out, background-color 85ms ease-in-out;
    opacity: 0.6;
    outline: none;
    position: relative;
    height: 100%;

    &:hover {
        opacity: 1;
    }

    &:disabled {
        opacity: 0.2;
        cursor: default;
    }

    ${props => props.active && `opacity: 1;`};

    svg {
        pointer-events: none;
        color: rgb(29, 30, 30);
        ${({ active }) => active && `opacity: 1;`};

        [data-theme="dark"] & {
            color: rgb(255, 255, 255);
        }
    }
`;
