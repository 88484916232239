export const hideCollapsedHeadersContentPreProcessor = rootNodes => {
    const processNodes = (nodes: typeof rootNodes) => {
        let smallestCollapsedHeaderLevel: number | null = null;

        nodes.forEach(node => {
            let isInterestingHeading = false;
            const isHeading = node.name?.startsWith("h");

            if (isHeading) {
                const headingLevel = Number(node.name[1]);
                if (smallestCollapsedHeaderLevel === null || headingLevel < smallestCollapsedHeaderLevel) {
                    isInterestingHeading = true;
                    const isCollapsed = node.attribs.collapsed === "true";

                    smallestCollapsedHeaderLevel = isInterestingHeading && isCollapsed ? headingLevel : null;
                }
            }

            if (isInterestingHeading) {
                return;
            }

            if (!smallestCollapsedHeaderLevel) return;

            if (isHeading) {
                const headingLevel = Number(node.name[1]);
                if (headingLevel > smallestCollapsedHeaderLevel) {
                    node.attribs.style = "display: none";
                }
            } else {
                node.attribs.style = "display: none";
            }

            if (node.children) {
                processNodes(node.children);
            }
        });
    };

    processNodes(rootNodes);
};

// At the time of writing, we decided to ignore font-family
// in the editor, and render all content with the default font.
// I.e. we're not using tiptap's FontFamily extension.
//
// The editor will take care of displaying the default font family
// now, however, for content rendered outside the editor, like in
// this `HTMLText` component, we have to manually remove the font-family:
export const removeFontFamilyAttributePreProcessor = rootNodes => {
    const processNodes = (nodes: typeof rootNodes) => {
        nodes.forEach(node => {
            // tiptap's text style attributes are in `span` tags
            if (node.type === "tag" && node.name === "span") {
                if (node.attribs.style?.includes("font-family")) {
                    node.attribs.style = node.attribs.style
                        .split(";")
                        .filter(style => !style.includes("font-family"))
                        .join(";");
                }
            }

            if (node.children) processNodes(node.children);
        });
    };

    processNodes(rootNodes);
};

// Since line height is defined in the richEditor css file, its hard to override it from a parent component.
// To combat this, we swap the line height attribute with a custom attribute, and then we can override it from a parent component.

export const swapLineHeightAttributePreProcessor = (rootNodes, lineHeight) => {
    const processNodes = (nodes: typeof rootNodes) => {
        nodes.forEach(node => {
            if (node.type === "tag" && node.name === "p") {
                node.attribs = { ...node.attribs, style: `${node.attribs.style}; line-height: ${lineHeight}` };
            }

            if (node.children) processNodes(node.children);
        });
    };

    processNodes(rootNodes);
};
