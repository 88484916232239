import { TextAlign as BaseTextAlign } from "@tiptap/extension-text-align";

export const TextAlign = BaseTextAlign.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            types: ["paragraph", "heading"],
        };
    },
});
