import { Heading as BaseHeading, Level } from "@tiptap/extension-heading";

export const Heading = BaseHeading.extend({
    addOptions() {
        return {
            ...(this.parent?.() ?? {}),
            levels: [1, 2, 3, 4] as Level[],
        };
    },
    addAttributes() {
        return {
            ...(this.parent?.() ?? {}),
            collapsed: { default: false },
        };
    },
});
