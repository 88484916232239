import Menu, { menuClasses } from "@mui/material/Menu";
import { HIGHLIGHTS } from "../constants";
import { Button } from "./DropdownButtons";
import styles from "./colorAndHighlightMenu.module.css";
import { ColorAndHighlightMenuProps } from "./types";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { FlexColumn } from "@/components/Flex";

const HighlightMenu = ({ editor, isOpen, anchorEl, onClose }: ColorAndHighlightMenuProps) => {
    return (
        <Menu
            open={isOpen}
            anchorReference={"anchorPosition"}
            anchorPosition={{
                left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
                top: anchorEl ? anchorEl.getBoundingClientRect().top + 30 : 0,
            }}
            onClose={() => {
                editor.commands.focus();
                onClose();
            }}
            sx={{
                [`.${menuClasses.paper}`]: {
                    padding: 0,
                    boxShadow: "rgb(0 0 0 / 8%) 0 0.4rem 1.6rem 0",
                },
                [`.${menuClasses.list}`]: {
                    backgroundColor: "var(--color-neutral-white)",
                },
            }}>
            <FlexColumn style={{ rowGap: spacing.XS }}>
                <div className={styles.title}>Highlights</div>
                <Button
                    label={"Default"}
                    isActive={!editor?.getAttributes("highlight").color}
                    onClick={() => {
                        editor.chain().focus().unsetHighlight().run();
                        onClose();
                    }}
                />
                {Object.values(HIGHLIGHTS).map(highlight => (
                    <Button
                        key={highlight.label}
                        isActive={highlight.value === editor?.getAttributes("highlight").color}
                        label={highlight.label}
                        bg={highlight.cssVar}
                        fg={themeColors.neutralBlack}
                        onClick={() => {
                            editor.chain().focus().setHighlight({ color: highlight.value }).run();
                            onClose();
                        }}
                    />
                ))}
            </FlexColumn>
        </Menu>
    );
};

export default HighlightMenu;
