import { Highlight as BaseHighlight } from "@tiptap/extension-highlight";

type HighlightOptions = {
    colors: string[];
    defaultColor: string;
};

export const Highlight = BaseHighlight.extend<HighlightOptions>({
    addOptions() {
        return {
            colors: ["yellow", "red", "blue", "purple", "green"],
            defaultColor: "yellow",
        };
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            color: {
                default: this.options.defaultColor,
                parseHTML: element => {
                    const foundColor = element.getAttribute("data-color");
                    return foundColor && this.options.colors.includes(foundColor) ? foundColor : null;
                },
                renderHTML: attributes => (attributes.color ? { "data-color": attributes.color } : {}),
            },
        };
    },
});
