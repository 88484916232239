import React, { useRef, useEffect } from "react";
import { XIcon, ExternalLinkIcon, Trash2Icon } from "lucide-react";
import styled from "styled-components";
import { Editor } from "@knowt/editor/react";
import { toast } from "react-hot-toast";
import { spacing } from "@/utils/spacing";
import { iconSizes } from "@/utils/iconProps";

const isUrl = (text: string) => {
    if (text.match(/\n/)) return false;

    try {
        const url = new URL(text);
        return url.hostname !== "";
    } catch (err) {
        return false;
    }
};

type LinkEditorProps = {
    editor: Editor;
    handleClose: () => void;
};

const LinkEditor = ({ editor, handleClose }: LinkEditorProps) => {
    const { current: initialHref } = useRef<string | undefined>(editor.getAttributes("link").href);
    const [href, setHref] = React.useState(initialHref ?? "");

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimeout(() => inputRef.current?.focus());
    }, []);

    const handleSubmit = () => {
        const trimmedHref = href.trim();

        if (trimmedHref === "") {
            handleRemoveLink();
            return;
        }

        if (!isUrl(trimmedHref)) {
            toast.error("Invalid URL");
            return;
        }

        editor.commands.setLink({ href: trimmedHref });
        handleClose();
    };

    const handleRemoveLink = () => {
        editor.commands.unsetLink();
        handleClose();
    };

    return (
        <Wrapper>
            <Input
                ref={inputRef}
                value={href}
                placeholder={"Paste or type a link"}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
                onChange={event => setHref(event.target.value)}
            />
            <Button
                className={"strippedBtn"}
                disabled={!isUrl(href)}
                onClick={event => {
                    event.preventDefault();
                    window.open(href, "_blank");
                }}>
                <span title={"Open link"} aria-label={"Open link"}>
                    <ExternalLinkIcon size={iconSizes.SM} />
                </span>
            </Button>
            <Button className={"strippedBtn"} onClick={handleRemoveLink}>
                {initialHref ? (
                    <span title={"Remove link"} aria-label={"Remove link"}>
                        <Trash2Icon size={iconSizes.SM} />
                    </span>
                ) : (
                    <span title={"Remove link"} aria-label={"Remove link"}>
                        <XIcon size={iconSizes.SM} />
                    </span>
                )}
            </Button>
        </Wrapper>
    );
};

export default LinkEditor;

const Wrapper = styled.div`
    border-radius: 4px;
    display: flex;
    background-color: var(--color-neutral-white);
    min-width: 32rem;
    pointer-events: all;
`;

const Input = styled.input`
    padding: ${spacing.XXS} ${spacing.XS};
    background-color: var(--color-neutral-white);
    color: var(--color-neutral-black);
    font-size: 15px;
    border-radius: 2px;
    border: 0;
    margin: 0;
    outline: none;
    flex-grow: 1;

    @media (hover: none) and (pointer: coarse) {
        font-size: 16px;
    }
`;

const Button = styled.button`
    padding: ${spacing.XS};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    [data-theme="dark"] &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:disabled {
        opacity: 0.5;
        cursor: auto;
    }
`;
