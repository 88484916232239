import { Mathematics as BaseMathematics } from "@tiptap-pro/extension-mathematics";

export const MATHEMATICS_REGEX = /\$\$(.*?)\$\$/gi;

export const Mathematics = BaseMathematics.extend({
    addOptions() {
        return {
            regex: MATHEMATICS_REGEX,
        };
    },
});
