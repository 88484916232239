import { useCallback, useEffect } from "react";
import useInterval from "react-use/lib/useInterval";

export const useAutoSave = ({
    isDirty,
    save: _save,
    interval,
    block = false,
    warningText = "You have unsaved changes - are you sure you wish to leave this page?",
}: {
    isDirty: () => boolean;
    save: () => Promise<void>;
    interval: number;
    block?: boolean;
    warningText?: string;
}) => {
    const save = useCallback(() => isDirty() && _save(), [isDirty, _save]);

    useInterval(save, interval);

    useEffect(() => {
        // Save on window blur
        window.addEventListener("visibilitychange", save);

        return () => {
            window.removeEventListener("visibilitychange", save);
        };
    }, [save, isDirty]);

    useEffect(() => {
        const handleWindowClose = (event?: BeforeUnloadEvent) => {
            if (!isDirty()) return;
            save();

            if (event) {
                return (event.returnValue = warningText);
            }
        };

        if (block) {
            window.addEventListener("beforeunload", handleWindowClose);
        }

        return () => {
            if (block) {
                window.removeEventListener("beforeunload", handleWindowClose);
            }
        };
    }, [block, isDirty, warningText]);
};
