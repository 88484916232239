import clsx from "clsx";
import { CheckIcon } from "lucide-react";
import styles from "./colorAndHighlightMenu.module.css";
import { FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

const ColorIcon = ({ bg, fg }: { bg?: string; fg?: string }) => {
    return (
        <div className={clsx(styles.colorIconBg)} style={{ backgroundColor: bg }}>
            <span className="bold" style={{ color: fg, fontSize: "1.6rem" }}>
                A
            </span>
        </div>
    );
};

export type ButtonProps = {
    isActive: boolean;
    label: string;
    onClick: () => void;
    fg?: string;
    bg?: string;
};

export const Button = ({ isActive, label, onClick, fg, bg }: ButtonProps) => (
    <button className={clsx("strippedBtn", styles.item)} onClick={() => onClick()}>
        <FlexRowAlignCenter>
            <ColorIcon fg={fg} bg={bg} />
            <span style={{ fontSize: "1.4rem" }}>{label}</span>
        </FlexRowAlignCenter>
        {isActive && <CheckIcon size={iconSizes.XS} color={themeColors.neutralBlack} />}
    </button>
);
