import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import { fromEntries } from "@/utils/genericUtils";
import { ItemType, Note } from "@knowt/syncing/graphql/schema";
import { useCallback, useMemo, useRef } from "react";
import useSWR from "swr";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import {
    callGetNote,
    callListNotesByClass,
    callListNotesByFolder,
    callListNotesNoContent,
    callListNotesWithContent,
    fetchNotesMetadata,
} from "./graphqlUtils";
import { resolveNotesSWRKey, resolveNoteSWRKey, saveNote } from "./utils";
import { filterClassItems } from "@/utils/course";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useAllNotesContent = ({
    isEnabled = true,
}: {
    isEnabled?: boolean;
} = {}) => {
    const { userId } = useCurrentUser();

    const {
        data: notesContent,
        mutate,
        error,
    } = useSWRImmutable(userId && isEnabled && ["notes-content", userId], () => callListNotesWithContent(userId));

    const isLoading = !notesContent && !error;

    // used to store the notes content while swr is still fetching.
    // tempCache will be merged with swr data when it became available,
    // and then, it will be set to null and not used.
    const tempCache = useRef<Record<string, string>>({});

    const _mutateTempCache = useCallback((noteId: string, content: string) => {
        if (tempCache.current !== null) {
            tempCache.current[noteId] = content;
        }
    }, []);

    const _mergeTempCacheIntoSWRCache = useCallback(
        async () => mutate(oldContents => ({ ...oldContents, ...tempCache.current }), { revalidate: false }),
        [mutate]
    );

    const _mutateSWRCache = useCallback(
        async (noteId: string, content: string) =>
            mutate(oldContents => ({ ...oldContents, [noteId]: content }), { revalidate: false }),
        [mutate]
    );

    const mutateNotesContent = useCallback(
        async ({ noteId, content }) => {
            if (isLoading) {
                return _mutateTempCache(noteId, content);
            }

            if (tempCache.current) {
                await _mergeTempCacheIntoSWRCache();
                tempCache.current = null;
            }

            return await _mutateSWRCache(noteId, content);
        },
        [isLoading, _mutateTempCache, _mergeTempCacheIntoSWRCache, _mutateSWRCache]
    );

    return { notesContent, mutateNotesContent };
};

export const useAllNotes = ({
    isEnabled = true,
    fallbackData = undefined,
}: {
    isEnabled?: boolean;
    fallbackData?: Record<string, Note>;
}) => {
    const { userId } = useCurrentUser();

    const { data, mutate } = useSWRImmutable(
        resolveNotesSWRKey({ userId, isEnabled }),
        () => callListNotesNoContent({ userId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { allNotes: data, mutate };
};

export const useNotebook = ({
    inTrash = false,
    isEnabled = true,
    showFoldersNotes = false,
    showClassNotes = false,
    fallbackData = undefined,
}: {
    inTrash?: boolean;
    isEnabled?: boolean;
    showFoldersNotes?: boolean;
    showClassNotes?: boolean;
    fallbackData?: Record<string, Note> | undefined;
}) => {
    const { allNotes, mutate } = useAllNotes({ isEnabled, fallbackData });

    const notes = useMemo(() => {
        if (!allNotes) return undefined;

        return fromEntries(
            Object.entries(allNotes)
                .filter(([, { trash }]) => trash === inTrash)
                .filter(([, { classId }]) => !classId || showClassNotes)
                .filter(([, { folderId }]) => !folderId || showFoldersNotes)
        );
    }, [allNotes, inTrash, showClassNotes, showFoldersNotes]);

    return { notes, mutate };
};

export const useNote = ({
    noteId,
    fallbackData,
    isEnabled = true,
}: {
    noteId?: string | null;
    fallbackData?: Note;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();
    const { update } = useUpdateNote(noteId);

    const {
        data: note,
        error,
        mutate,
    } = useSWR(
        resolveNoteSWRKey({ userId, noteId, isEnabled }),
        async ([_, noteId, _userId]) => {
            const storage = await platform.storage();
            const password = (await storage.getWithExpiry(`${ItemType.NOTE}-${noteId}-pwd`)) as string | null;

            return await callGetNote({ noteId, password });
        },
        {
            fallbackData,
        }
    );

    return { note, error, isLoading: !note && !error, update, mutate };
};

export const useUpdateNote = (noteId?: string | null) => {
    const { userId } = useCurrentUser();

    const update = useCallback(
        async (newNoteData: Partial<Note>) => {
            if (!userId || !noteId) {
                const { log } = await platform.analytics.logging();
                log("Canceling update -- no user ID provided");
                return null;
            }

            return await saveNote({ ...newNoteData, noteId, userId });
        },
        [userId, noteId]
    );

    return { update };
};

/**
 * Returns the metadata for the given note IDs (everything but content)
 */
export const useNotesMetadata = (noteIds: string[], isEnabled = true) => {
    const { userId } = useCurrentUser();

    const { data: notesMetadata } = useSWRImmutable(
        isEnabled && noteIds ? ["notesMetadata", noteIds, userId] : null,
        async ([_, noteIds, _userId]) => fetchNotesMetadata(noteIds)
    );

    return { notesMetadata };
};

export const useFolderNotes = ({
    folderId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    folderId: string | null | undefined;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Note>;
}) => {
    const { userId } = useCurrentUser();

    const { data, error } = useSWRImmutable(
        resolveNotesSWRKey({ userId, folderId, isEnabled: isEnabled && !!folderId }),
        () => callListNotesByFolder({ folderId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const folderNotes = useMemo(() => {
        if (!data) return null;

        return fromEntries(
            Object.entries(data).filter(([, note]) => note.trash === inTrash && note.folderId === folderId)
        );
    }, [data, folderId, inTrash]);

    return { folderNotes, error, isLoading: !error && !data };
};

export const useClassNotes = ({
    classId,
    sectionId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    classId: string;
    sectionId?: string | null | undefined;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Note> | undefined;
}) => {
    const { userId, user } = useCurrentUser();

    const { data, error } = useSWRImmutable(
        resolveNotesSWRKey({ userId, classId, isEnabled: isEnabled && !!classId }),
        () => callListNotesByClass({ classId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const classNotes = useMemo(() => {
        if (!data) return {};

        return fromEntries(filterClassItems({ user, classId, items: data, inTrash, sectionId }));
    }, [data, classId, inTrash, sectionId, user]);

    return { classNotes, error, isLoading: !error && !data };
};
