/**
 * Adopted from https://github.com/outline/rich-markdown-editor/blob/main/src/plugins/Folding.tsx.
 *
 * This plugin adds a class to collapsed headings so that they can be hidden from CSS.
 *
 * Note that this logic doesn't work well with tables, so in another
 *  file, we remove the option to collapse headings inside tables.
 */

import { Editor, NodeWithPos } from "@knowt/editor/core";
import { Node, NodeType } from "@knowt/editor/pm/model";
import { Plugin, TextSelection } from "@knowt/editor/pm/state";
import { Decoration, DecorationSet } from "@knowt/editor/pm/view";

const findBlockNodes = (doc: Node): NodeWithPos[] => {
    const result: NodeWithPos[] = [];

    doc.descendants((node, pos) => {
        if (node.isBlock) result.push({ node, pos });
    });

    return result;
};

const findCollapsedNodes = (doc: Node, name: string): NodeWithPos[] => {
    const blocks = findBlockNodes(doc);
    const nodes: NodeWithPos[] = [];

    let withinCollapsedHeading;

    for (const block of blocks) {
        if (block.node.type.name === name) {
            const { level, collapsed } = block.node.attrs;

            if (!withinCollapsedHeading || level <= withinCollapsedHeading) {
                withinCollapsedHeading = collapsed ? level : undefined;
                continue;
            }
        }

        if (withinCollapsedHeading) {
            nodes.push(block);
        }
    }

    return nodes;
};

export const hideCollapsedNodesPlugin = (name: string) => {
    return new Plugin({
        props: {
            decorations: ({ doc }) => {
                const decorations: Decoration[] = findCollapsedNodes(doc, name).map(block => {
                    return Decoration.node(block.pos, block.pos + block.node.nodeSize, { class: "folded-content" });
                });

                return DecorationSet.create(doc, decorations);
            },
        },
    });
};

// For collapsible headings, we can't anymore rely on the default `Enter` behaviour,
// e.g. if the header was collapsed and we pressed `Enter`, it should jump to after
// the collapsed hidden content, not create a new paragraph inside the collapsed.
//
// This function handles such cases.
export const splitHeading = (editor: Editor, type: NodeType) => {
    const { state } = editor.view;
    const { $from, from, $to, to } = state.selection;

    // check we're in a matching heading node
    if ($from.parent.type.name !== type.name) return false;

    // check that the caret is at the end of the content, if it isn't then
    // standard node splitting behaviour applies
    const endPos = $to.after() - 1;
    if (endPos !== to) return false;

    // If the node isn't collapsed standard behavior applies
    if (!$from.parent.attrs.collapsed) return false;

    // Find the next visible block after this one. It takes into account nested
    // collapsed headings and reaching the end of the document
    const allBlocks = findBlockNodes(state.doc);
    const collapsedBlocks = findCollapsedNodes(state.doc, type.name);

    const visibleBlocks = allBlocks.filter(a => !collapsedBlocks.find(b => b.pos === a.pos));

    const nextVisibleBlock = visibleBlocks.find(a => a.pos > from);

    const pos = nextVisibleBlock ? nextVisibleBlock.pos : state.doc.content.size;

    // Insert our new heading directly before the next visible block
    const transaction = state.tr.insert(pos, type.create({ ...$from.parent.attrs, collapsed: false }));

    // Move the selection into the new heading node and make sure it's on screen
    editor.view.dispatch(
        transaction
            .setSelection(TextSelection.near(transaction.doc.resolve(Math.min(pos + 1, transaction.doc.content.size))))
            .scrollIntoView()
    );

    return true;
};
