import { useCallback, useEffect, useRef } from "react";
import { debounce } from "@/utils/genericUtils";

const KEY = "_ignoredKey";

type DefaultDebounceCallbackFn = (...args: unknown[]) => unknown;

export const useDebouncedCallback = <T extends DefaultDebounceCallbackFn>(callback: T, interval = 1000) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const debouncedFnRef = useRef(null);

    if (debouncedFnRef.current === null) {
        debouncedFnRef.current = debounce((_key, ...params) => {
            callbackRef.current(...params);
        }, interval);
    }

    return useCallback((...params) => debouncedFnRef.current(KEY, ...params), []) as (
        ...params: Parameters<T>
    ) => ReturnType<T>;
};
