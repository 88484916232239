import React from "react";
import { ReactNodeViewRenderer } from "@knowt/editor/react";
import { Document } from "@knowt/editor/extensions/document";
import { Text } from "@knowt/editor/extensions/text";
import { Blockquote } from "@knowt/editor/extensions/blockquote";
import { Bold } from "@knowt/editor/extensions/bold";
import { BulletList } from "@knowt/editor/extensions/bulletList";
import { OrderedList } from "@knowt/editor/extensions/orderedList";
import { ListItem } from "@knowt/editor/extensions/listItem";
import { TaskList } from "@knowt/editor/extensions/taskList";
import { TaskItem } from "@knowt/editor/extensions/taskItem";
import { Code } from "@knowt/editor/extensions/code";
import { HardBreak } from "@knowt/editor/extensions/hardBreak";
import { Heading } from "@knowt/editor/extensions/heading";
import { headingCollapseControlPlugin } from "../plugins/headingCollapseControlPlugin";
import { hideCollapsedNodesPlugin, splitHeading } from "../plugins/hideCollapsedNodesPlugin";
import { HorizontalRule } from "@knowt/editor/extensions/horizontalRule";
import { Italic } from "@knowt/editor/extensions/italic";
import { Link } from "@knowt/editor/extensions/link";
import { Paragraph } from "@knowt/editor/extensions/paragraph";
import { Strike } from "@knowt/editor/extensions/strike";
import { Underline } from "@knowt/editor/extensions/underline";
import { TextStyle } from "@knowt/editor/extensions/textStyle";
import { Color } from "@knowt/editor/extensions/color";
import { Highlight } from "@knowt/editor/extensions/highlight";
import { Table, TableRow, TableHeader, TableCell } from "@knowt/editor/extensions/table";
import { tablePlugin } from "../plugins/tableControlsPlugin";
import { History } from "@knowt/editor/extensions/history";
import { Mathematics } from "@knowt/editor/extensions/mathematics";
import { Subscript } from "@knowt/editor/extensions/subscript";
import { Superscript } from "@knowt/editor/extensions/superscript";
import { Emoji } from "@knowt/editor/extensions/emoji";
import { TextAlign } from "@knowt/editor/extensions/textAlign";
import { Typography } from "@knowt/editor/extensions/typography";
import { Gapcursor } from "@knowt/editor/extensions/gapcursor";
import { Dropcursor } from "@knowt/editor/extensions/dropcursor";
import { Details } from "@knowt/editor/extensions/details";
import { DetailsSummary } from "@knowt/editor/extensions/detailsSummary";
import { DetailsContent } from "@knowt/editor/extensions/detailsContent";
import { Youtube } from "@knowt/editor/extensions/youtube";
import { Placeholder } from "@knowt/editor/extensions/placeholder";
import { Image } from "@knowt/editor/extensions/image";
import { CodeBlockLowlight } from "@knowt/editor/extensions/codeBlockLowlight";
import { CharacterCount } from "@knowt/editor/extensions/characterCount";
import { SlashCommand, isSlashCommandAllowed } from "../SlashMenu/suggestion";
import { SlashCommandOptions } from "../SlashMenu/types";
import ImageComponent from "../nodeViews/ImageComponent";
import CodeBlockComponent from "../nodeViews/CodeBlockComponent";
import { emojiSuggestion } from "../EmojiMenu";
import { AiInputMenuTrigger, AiInputMenuTriggerOptions } from "./AiInputMenuTrigger";
import { NonPersistentHighlight } from "./NonPersistentHighlight";
import { MenusState } from "./MenusState";
import { CustomPlaceholder } from "./CustomPlaceholder";

export const extensions = ({
    slashCommandOptions,
    aiInputMenuTriggerOptions,
    placeholderOptions,
    customPlaceholder,
    characterCountLimit,
}: {
    slashCommandOptions?: Partial<SlashCommandOptions>;
    aiInputMenuTriggerOptions?: Partial<AiInputMenuTriggerOptions> & { disabled?: boolean };
    placeholderOptions?: { placeholder: string };
    customPlaceholder?: React.ReactElement;
    characterCountLimit?: number;
} = {}) => [
    Document,
    Text,
    Bold,
    Italic,
    Underline,
    Strike,
    Paragraph,
    Heading.extend({
        addProseMirrorPlugins() {
            return [
                ...(this.parent?.() ?? []),
                headingCollapseControlPlugin(this.editor, this.name),
                hideCollapsedNodesPlugin(this.name),
            ];
        },
        addKeyboardShortcuts() {
            return {
                ...(this.parent?.() ?? {}),
                Enter: () => splitHeading(this.editor, this.type),
            };
        },
    }),
    Blockquote,
    Code,
    Link,
    TaskList,
    TaskItem,
    BulletList,
    OrderedList,
    ListItem,
    HardBreak,
    HorizontalRule,
    TextStyle,
    Color,
    Highlight,
    History,
    Table.extend({
        addProseMirrorPlugins() {
            return [...(this.parent?.() ?? []), tablePlugin(this.editor)];
        },
    }),
    TableRow,
    TableHeader,
    TableCell,
    Mathematics,
    Subscript,
    Superscript,
    Emoji.configure({ suggestion: emojiSuggestion }),
    TextAlign,
    Typography,
    Gapcursor,
    Dropcursor,
    Details.configure({ HTMLAttributes: { class: "details" } }),
    Placeholder.configure({
        includeChildren: true,
        placeholder: ({ editor }) => {
            if (!isSlashCommandAllowed({ editor })) return "";

            if (editor.isActive("heading")) {
                return `Heading ${editor.getAttributes("heading").level}`;
            }

            return placeholderOptions?.placeholder ?? "Press 'space' for Al and '/' for formatting";
        },
    }),
    ...(customPlaceholder ? [CustomPlaceholder.configure({ placeholder: customPlaceholder })] : []),
    ...(aiInputMenuTriggerOptions?.disabled
        ? []
        : [
              AiInputMenuTrigger.configure({
                  canShow: ({ editor }) => isSlashCommandAllowed({ editor }),
                  ...aiInputMenuTriggerOptions,
              }),
          ]),
    DetailsSummary,
    DetailsContent,
    Image.extend({
        addNodeView() {
            return ReactNodeViewRenderer(ImageComponent);
        },
    }),
    Youtube,
    CodeBlockLowlight.extend({
        addNodeView() {
            return ReactNodeViewRenderer(CodeBlockComponent);
        },
    }),
    SlashCommand.configure(slashCommandOptions),
    NonPersistentHighlight,
    MenusState,
    ...(characterCountLimit ? [CharacterCount.configure({ limit: characterCountLimit })] : []),
];
