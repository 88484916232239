import noop from "@/utils/noop";
import Suggestion from "@knowt/editor/plugins/suggestion";
import { Plugin, PluginKey } from "@knowt/editor/pm/state";
import { Editor, Extension } from "@tiptap/core";
import {
    BaselineIcon,
    CircleIcon,
    CodeIcon,
    Heading1Icon,
    Heading2Icon,
    Heading3Icon,
    Heading4Icon,
    ImageIcon,
    Link2Icon,
    ListIcon,
    ListOrderedIcon,
    ListTodoIcon,
    MinusIcon,
    SubscriptIcon,
    SuperscriptIcon,
    TableIcon,
    TextIcon,
    TextQuoteIcon,
} from "lucide-react";
import Image from "next/image";
import { AICompletionType } from "@knowt/syncing/graphql/schema";
import { COLORS, HIGHLIGHTS } from "../constants";
import { MENUS_STATE_META_KEY, Menus } from "../extensions/MenusState";
import { CommandProps, Group, SlashCommandOptions } from "./types";
import {
    AI_PROMPTS,
    AiEditorMutationType,
} from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";
import { themeColors } from "@/utils/themeColors";
import { iconSizes } from "@/utils/iconProps";
import { assertTruthy } from "@knowt/syncing/utils/assertions";
import { SLASH_MENU_BUTTON_CLASS_NAME } from "@/components/FullPageEditor/SlashMenu/constants";

export const IS_SLASH_COMMAND_DISABLED_KEY = "isSlashCommandDisabled";

export const getIsSlashCommandDisabledValue = () => {
    return;
};

export const isSlashCommandAllowed = ({ editor }: { editor: Editor }) => {
    const isSlashCommandsEnabled = !getIsSlashCommandDisabledValue();

    return isSlashCommandsEnabled && !editor.isActive("codeBlock") && !editor.isActive("image");
};

export const SlashCommand = Extension.create<SlashCommandOptions>({
    addOptions() {
        return {
            updateMenuProps: noop,
            aiCommands: [],
            // we can't add the other options default values here (to fix the ts error),
            // cos tiptap uses deepMerge to merge those default options with the provided
            // ones, and the default merge strategy doesn't work well with our use cases.
        };
    },
    name: "slash-command",
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("slash-command-dom-events"),
                props: {
                    handleDOMEvents: {
                        blur: (_, event) => {
                            const isBlurredBecauseSlashMenuButtonWasClicked =
                                event.relatedTarget?.classList?.contains?.(SLASH_MENU_BUTTON_CLASS_NAME);

                            if (!isBlurredBecauseSlashMenuButtonWasClicked)
                                this.options.updateMenuProps({ isOpen: false });

                            return false;
                        },
                    },
                },
            }),
            Suggestion({
                editor: this.editor,
                char: "/",
                command: ({ editor, range, props }) => props.command({ editor, range }),
                items: ({ query, editor }) =>
                    getSuggestionItems({
                        query,
                        editor,
                        aiCommands: this.options.aiCommands,
                        handleChooseAiPromptRef: this.options.handleChooseAiPromptRef,
                        updateMenuProps: this.options.updateMenuProps,
                        handleInsertImageRef: this.options.handleInsertImageRef,
                    }),
                allow: isSlashCommandAllowed,
                allowedPrefixes: null,
                render: () => {
                    return {
                        onStart: props => {
                            this.options.updateMenuProps({ isOpen: true, ...props });
                        },
                        onUpdate: props => {
                            this.options.updateMenuProps(props);
                        },
                        onKeyDown: (props: { event: KeyboardEvent }) => {
                            if (props.event.key === "Escape") {
                                this.options.updateMenuProps({ isOpen: false });
                                return true;
                            }

                            return this.options.componentRef.current.handleKeyDown(props.event);
                        },
                        onExit: () => {
                            this.options.updateMenuProps({ isOpen: false });
                        },
                    };
                },
            }),
        ];
    },
});

const getSuggestionItems = ({
    query,
    editor,
    aiCommands,
    handleChooseAiPromptRef,
    updateMenuProps,
    handleInsertImageRef,
}: {
    query: string;
    editor: Editor;
    aiCommands: (AICompletionType | AiEditorMutationType)[];
    handleChooseAiPromptRef: SlashCommandOptions["handleChooseAiPromptRef"];
    updateMenuProps: SlashCommandOptions["updateMenuProps"];
    handleInsertImageRef: SlashCommandOptions["handleInsertImageRef"];
}): Group[] => {
    const allGroups: Group[] = [
        makeAiGroup({ handleChooseAiPromptRef, updateMenuProps, aiCommands }),
        {
            name: "Typography",
            items: [
                {
                    title: "Text",
                    description: "Just start typing with plain text.",
                    searchTerms: ["p", "paragraph"],
                    icon: props => <TextIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setParagraph().run();
                    },
                },
                {
                    title: "Heading 1",
                    description: "Big section heading.",
                    searchTerms: ["title", "big", "large", "h1"],
                    icon: props => <Heading1Icon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setNode("heading", { level: 1 }).run();
                    },
                },
                {
                    title: "Heading 2",
                    description: "Medium section heading.",
                    searchTerms: ["subtitle", "medium", "h2"],
                    icon: props => <Heading2Icon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setNode("heading", { level: 2 }).run();
                    },
                },
                {
                    title: "Heading 3",
                    description: "Small section heading.",
                    searchTerms: ["subtitle", "small", "h3"],
                    icon: props => <Heading3Icon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setNode("heading", { level: 3 }).run();
                    },
                },
                {
                    title: "Heading 4",
                    description: "Smaller section heading.",
                    searchTerms: ["subtitle", "smaller", "h4"],
                    icon: props => <Heading4Icon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setNode("heading", { level: 4 }).run();
                    },
                },
            ],
        },
        {
            name: "Lists",
            items: [
                {
                    title: "To-do List",
                    description: "Track tasks with a to-do list.",
                    searchTerms: ["todo", "task", "list", "check", "checkbox"],
                    icon: props => <ListTodoIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).toggleTaskList().run();
                    },
                },
                {
                    title: "Bullet List",
                    description: "Create a simple bullet list.",
                    searchTerms: ["unordered", "point"],
                    icon: props => <ListIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).toggleBulletList().run();
                    },
                },
                {
                    title: "Numbered List",
                    description: "Create a list with numbering.",
                    searchTerms: ["ordered"],
                    icon: props => <ListOrderedIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
                    },
                },
            ],
        },
        {
            name: "Misc",
            items: [
                ...(editor.isActive("table")
                    ? []
                    : [
                          {
                              title: "Table",
                              description: "Insert a table.",
                              searchTerms: ["table"],
                              icon: props => <TableIcon size={iconSizes.SM} {...props} />,
                              command: ({ editor, range }: CommandProps) => {
                                  editor.chain().focus().deleteRange(range).insertTable().run();
                              },
                          },
                      ]),
                {
                    title: "Quote",
                    description: "Capture a quote.",
                    searchTerms: ["blockquote"],
                    icon: props => <TextQuoteIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) =>
                        editor.chain().focus().deleteRange(range).toggleBlockquote().run(),
                },
                {
                    title: "Code Block",
                    description: "Capture a code snippet.",
                    searchTerms: ["codeblock"],
                    icon: props => <CodeIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) =>
                        editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
                },
                {
                    title: "Subscript",
                    description: "Capture a subscript.",
                    searchTerms: ["subscript", "sub"],
                    icon: props => <SubscriptIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) =>
                        editor.chain().focus().deleteRange(range).toggleSubscript().run(),
                },
                {
                    title: "Superscript",
                    description: "Capture a superscript.",
                    searchTerms: ["superscript", "sup"],
                    icon: props => <SuperscriptIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) =>
                        editor.chain().focus().deleteRange(range).toggleSuperscript().run(),
                },
                {
                    title: "Image",
                    description: "Insert an image.",
                    searchTerms: ["image", "picture", "photo"],
                    icon: props => <ImageIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).run();
                        handleInsertImageRef.current();
                    },
                },
                {
                    title: "Link",
                    description: "Insert a link.",
                    searchTerms: ["link", "url", "href"],
                    icon: props => <Link2Icon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor
                            .chain()
                            .focus()
                            .deleteRange(range)
                            .setMeta(MENUS_STATE_META_KEY, { [Menus.LinkEditorMenu]: true })
                            .run();
                    },
                },
                {
                    title: "Divider",
                    description: "Separate content with a horizontal line.",
                    searchTerms: ["divider", "horizontal", "rule"],
                    icon: props => <MinusIcon size={iconSizes.SM} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).setHorizontalRule().run();
                    },
                },
            ],
        },
        {
            name: "Color",
            items: [
                {
                    title: "Default",
                    description: `Set color to default`,
                    searchTerms: ["default"],
                    icon: props => <BaselineIcon size={iconSizes.SM} color={themeColors.neutralBlack} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).unsetColor().run();
                    },
                },
                ...Object.values(COLORS).map(({ value: color, label: name, cssVar }) => ({
                    title: name,
                    description: `Set color to ${name}`,
                    searchTerms: [name],
                    icon: props => <BaselineIcon size={iconSizes.SM} color={cssVar} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).toggleColor(color).run();
                    },
                })),
            ],
        },
        {
            name: "Highlight",
            items: [
                {
                    title: "Default",
                    description: `Set highlight to default`,
                    searchTerms: ["default"],
                    icon: props => <CircleIcon size={iconSizes.SM} color={themeColors.neutralBlack} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).unsetHighlight().run();
                    },
                },
                ...Object.values(HIGHLIGHTS).map(({ value: highlight, label: name, cssVar }) => ({
                    title: name,
                    description: `Set highlight to ${name}`,
                    searchTerms: [name],
                    icon: props => <CircleIcon size={iconSizes.SM} color={cssVar} fill={cssVar} {...props} />,
                    command: ({ editor, range }: CommandProps) => {
                        editor.chain().focus().deleteRange(range).toggleHighlight({ color: highlight }).run();
                    },
                })),
            ],
        },
    ];

    const computeMatchingCoefficient = (str: string, query: string) => {
        const cleanedStr = str.toLowerCase().trim();
        const cleanedQuery = query.toLowerCase().trim();

        if (cleanedStr === cleanedQuery) return 25;
        if (cleanedStr.startsWith(cleanedQuery)) return 10;
        if (cleanedStr.includes(cleanedQuery)) return 1;

        return 0;
    };

    return allGroups
        .map(group => {
            const filteredItems = group.items
                .map(item => {
                    const { title, description, searchTerms } = item;

                    const matchingCoefficient =
                        computeMatchingCoefficient(title, query) +
                        computeMatchingCoefficient(description, query) +
                        searchTerms.reduce((acc, term) => acc + computeMatchingCoefficient(term, query), 0) +
                        computeMatchingCoefficient(group.name, query) / 2;

                    return { ...item, matchingCoefficient };
                })
                .filter(({ matchingCoefficient }) => matchingCoefficient > 0)
                .sort((itemA, itemB) => itemB.matchingCoefficient - itemA.matchingCoefficient);

            return { ...group, items: filteredItems };
        })
        .filter(({ items }) => items.length)
        .sort((groupA, groupB) => {
            const groupASum = groupA.items.reduce((acc, { matchingCoefficient }) => acc + matchingCoefficient, 0);
            const groupBSum = groupB.items.reduce((acc, { matchingCoefficient }) => acc + matchingCoefficient, 0);
            return groupBSum - groupASum;
        });
};

const makeAiGroup = ({
    aiCommands,
    handleChooseAiPromptRef,
    updateMenuProps,
}: {
    aiCommands: (AICompletionType | AiEditorMutationType)[];
    handleChooseAiPromptRef: SlashCommandOptions["handleChooseAiPromptRef"];
    updateMenuProps: SlashCommandOptions["updateMenuProps"];
}): Group => ({
    name: "AI",
    items: aiCommands
        .map(promptType => {
            const data = AI_PROMPTS[promptType];
            assertTruthy(data, `Unknown AI prompt type: ${promptType}`);
            return data;
        })
        .map(prompt => ({
            title: prompt.title,
            description: prompt.keywords,
            searchTerms: [prompt.searchKeyword],
            icon: () => <Image src={prompt.image} alt="pencil" width={15} height={15} />,
            command: ({ editor, range }) => {
                editor.chain().focus().deleteRange(range).run();
                updateMenuProps({ isOpen: false });
                handleChooseAiPromptRef.current(prompt);
            },
        })),
});
