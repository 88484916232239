/**
 * Copied from https://github.com/ueberdosis/tiptap/blob/9575dd363915b06252756c9b1f7308d1a588fcd2/packages/react/src/BubbleMenu.tsx
 * and modified to use the custom BubbleMenuPlugin instead of the default one.
 */

import { assertTruthy } from "@knowt/syncing/utils/assertions";
import React, { useEffect, useState } from "react";
import { BubbleMenuPlugin, BubbleMenuPluginProps } from "./bubbleMenuPlugin";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type BubbleMenuProps = Omit<Optional<BubbleMenuPluginProps, "pluginKey" | "editor">, "element"> & {
    className?: string;
    children: React.ReactNode;
    updateDelay?: number;
};

export const BubbleMenu = (props: BubbleMenuProps) => {
    const [element, setElement] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!element) return;

        if (props.editor?.isDestroyed) {
            return;
        }

        const { pluginKey = "bubbleMenu", editor, tippyOptions = {}, updateDelay, shouldShow = null } = props;
        assertTruthy(editor, "BubbleMenu must be passed an editor instance as prop.");

        const plugin = BubbleMenuPlugin({
            updateDelay,
            editor,
            element,
            pluginKey,
            shouldShow,
            tippyOptions,
        });

        editor.registerPlugin(plugin);
        return () => editor.unregisterPlugin(pluginKey);
    }, [props.editor, element]);

    return (
        <div ref={setElement} className={props.className} style={{ visibility: "hidden" }}>
            {props.children}
        </div>
    );
};
