import { Image as BaseImage } from "@tiptap/extension-image";

export const Image = BaseImage.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            inline: true,
        };
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            width: { default: null },
            height: { default: null },
        };
    },
    renderText() {
        return "%img%";
    },
});
