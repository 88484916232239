import { useState } from "react";
import LinkEditor from "../LinkEditorMenu/LinkEditor";
import { BubbleMenu } from "./BubbleMenu";
import CommandItems from "./CommandItems";
import { getSelectionType } from "./utils";
import { Editor } from "@knowt/editor/react";
import { AIPrompt } from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";

type SelectionMenuProps = {
    editor: Editor | null;
    showForTextSelection: boolean;
    handleOpenAiInputMenu: (options?: { selectedPrompt?: AIPrompt; optimisticText?: string }) => void;
};

const SelectionMenu = ({ editor, showForTextSelection, handleOpenAiInputMenu }: SelectionMenuProps) => {
    const [isLinkEditorOpened, setIsLinkEditorOpened] = useState(false);
    if (!editor) return null;

    const selectionType = getSelectionType(editor.state.selection);

    const handleClosed = () => setIsLinkEditorOpened(false);

    const renderBody = () => {
        const isTextSelection = selectionType === "text";
        if (isTextSelection && !showForTextSelection) return null;

        if (isLinkEditorOpened) {
            return <LinkEditor editor={editor} handleClose={() => setIsLinkEditorOpened(false)} />;
        }

        return (
            <CommandItems
                editor={editor}
                openLinkEditor={() => setIsLinkEditorOpened(true)}
                handleOpenAiInputMenu={handleOpenAiInputMenu}
            />
        );
    };

    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{
                moveTransition: "transform 0.15s ease-out",
                maxWidth: 1000,
                zIndex: 999,
                // TODO: this dynamic `placement` is not working
                placement: selectionType === "table-all" ? "top-start" : selectionType === "table-row" ? "left" : "top",
                onHidden: handleClosed,
            }}>
            {renderBody()}
        </BubbleMenu>
    );
};

export default SelectionMenu;
