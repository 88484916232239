export const getPasteTransformers = () => {
    return {
        transformPastedText: text => {
            return text.replace(/\xA0/g, " ");
        },
        transformPastedHTML: html => {
            // remove background-color, color
            return html.replace(
                /(background-color|color): ?(rgba?\(\s*\d+,\s*\d+,\s*\d+(,\s*\d+(?:\.\d+)?)?\)|hsla?\(\s*\d+,\s*\d+%,\s*\d+%(,\s*\d+(?:\.\d+)?)?\)|#[0-9a-fA-F]{3,6}|(aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|purple|red|silver|teal|white|yellow)\b);?/gi,
                ""
            );
        },
    };
};
