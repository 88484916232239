import { Extension } from "@knowt/editor/react";
import { Plugin, PluginKey } from "@knowt/editor/pm/state";

export const MENUS_STATE_META_KEY = "menusState";

export enum Menus {
    AiInputMenu = "aiInputMenu",
    LinkEditorMenu = "linkEditorMenu",
}

export type MenusStorageOptions = {
    // empty
};

export type MenusStateStorage = {
    openedMenusSet: Set<string>;
};

export const MenusState = Extension.create<MenusStorageOptions, MenusStateStorage>({
    name: "menusState",

    addStorage() {
        return {
            openedMenusSet: new Set<string>(),
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("menusState"),
                // listen for transactions with `MENUS_STATE_META_KEY` meta key
                appendTransaction: transactions => {
                    const menusStatesTr = transactions.find(tr => tr.getMeta(MENUS_STATE_META_KEY));

                    if (menusStatesTr) {
                        // an object of menuName: boolean pairs
                        const menusState = menusStatesTr.getMeta(MENUS_STATE_META_KEY);

                        for (const menuName in menusState) {
                            if (menusState[menuName] === true) this.storage.openedMenusSet.add(menuName);
                            if (menusState[menuName] === false) this.storage.openedMenusSet.delete(menuName);
                        }
                    }

                    return null;
                },
            }),
        ];
    },
});
