import { themeColors } from "@/utils/themeColors";

export type Item = {
    label: string;
    value: string;
    cssVar: string;
};

export const COLORS: Record<string, Item> = {
    yellow: { value: "yellow", label: "Yellow", cssVar: themeColors.richEditorTextColor1 },
    blue: { value: "blue", label: "Blue", cssVar: themeColors.richEditorTextColor2 },
    red: { value: "red", label: "Red", cssVar: themeColors.richEditorTextColor3 },
    purple: { value: "purple", label: "Purple", cssVar: themeColors.richEditorTextColor4 },
    green: { value: "green", label: "Green", cssVar: themeColors.richEditorTextColor5 },
};

export const HIGHLIGHTS: Record<string, Item> = {
    yellow: { value: "yellow", cssVar: themeColors.subject1, label: "Yellow" },
    blue: { value: "blue", cssVar: themeColors.subject2, label: "Blue" },
    red: { value: "red", cssVar: themeColors.subject3, label: "Red" },
    purple: { value: "purple", cssVar: themeColors.subject4, label: "Purple" },
    green: { value: "green", cssVar: themeColors.subject5, label: "Green" },
};
