import { Selection } from "@knowt/editor/pm/state";
import { isCellSelection } from "@knowt/editor/extensions/table";

export const getSelectionType = (selection: Selection) => {
    const isColumnSelection = isCellSelection(selection) && selection.isColSelection();
    const isRowSelection = isCellSelection(selection) && selection.isRowSelection();

    if (!isColumnSelection && !isRowSelection) {
        return "text";
    }

    const isTableSelection = isColumnSelection && isRowSelection;

    return isTableSelection ? "table-all" : isColumnSelection ? "table-column" : "table-row";
};
