// https://usehooks.com/useEventListener/
import React, { useEffect, useRef } from "react";

const useDOMEventListener = (
    eventName: keyof WindowEventMap,
    handler: (e: React.MouseEvent | UIEvent | Event | DeviceMotionEvent | React.KeyboardEvent) => void,
    element: Document | Element | Window = typeof window !== "undefined" ? window : undefined,
    options = false
) => {
    // Create a ref that stores handler
    const savedHandler = useRef<(e: React.MouseEvent) => void>();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            const isSupported = element && element?.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = (event: any) => savedHandler.current(event);

            // Add event listener
            element?.addEventListener(eventName, eventListener, options);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener, options);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

export default useDOMEventListener;
