import { ASSETS_URL } from "@/config/deployConstants";
import { AICompletionType } from "@knowt/syncing/graphql/schema";

const simpleFlashcardImg = `${ASSETS_URL}/images/AI_Prompts/SIMPLE_FLASCHARD.png`;
const fillBlankImg = `${ASSETS_URL}/images/AI_Prompts/FILL_BLANK_FLASHCARD.png`;
const questionFlashcardImg = `${ASSETS_URL}/images/AI_Prompts/QUESTION_FLASHCARD.png`;
const translateImg = `${ASSETS_URL}/images/AI_Prompts/TRANSLATE_FLASHCARD.png`;
const debateArgumentImg = `${ASSETS_URL}/images/AI_Prompts/DEBATE_ARGUMENT.png`;
const createNoteImg = `${ASSETS_URL}/images/AI_Prompts/CREATE_NOTE.png`;
const newspaperArticleImg = `${ASSETS_URL}/images/AI_Prompts/NEWSPAPER_ARTICLE.png`;
const mindMapImg = `${ASSETS_URL}/images/AI_Prompts/MIND_MAP.png`;
const keyVocabularyImg = `${ASSETS_URL}/images/AI_Prompts/KEY_VOCABULARY.png`;
const coverLetterImg = `${ASSETS_URL}/images/AI_Prompts/COVER_LETTER.png`;
const coldEmailImg = `${ASSETS_URL}/images/AI_Prompts/COLD_EMAIL.png`;
const prosConsImg = `${ASSETS_URL}/images/AI_Prompts/PROS_CONS.png`;
const outlineImg = `${ASSETS_URL}/images/AI_Prompts/OUTLINE.png`;
const brainstormImg = `${ASSETS_URL}/images/AI_Prompts/BRAINSTORM.png`;
const bookSumImg = `${ASSETS_URL}/images/AI_Prompts/BOOK_SUM.png`;
const summarizeImg = `${ASSETS_URL}/images/AI_Prompts/SUMMARIZE.png`;
const improveWritingImg = `${ASSETS_URL}/images/AI_Prompts/IMPROVE_WRITING.png`;
const fixSpellingGrammarImg = `${ASSETS_URL}/images/AI_Prompts/FIX_SPELLING_GRAMMAR.png`;
const addMoreDetailsImg = `${ASSETS_URL}/images/AI_Prompts/ADD_MORE_DETAILS.png`;
const eli5Img = `${ASSETS_URL}/images/AI_Prompts/ELI5.png`;
const findQuotesImg = `${ASSETS_URL}/images/AI_Prompts/FIND_QUOTES.png`;
const studyPlanImg = `${ASSETS_URL}/images/AI_Prompts/CREATE_STUDY_PLAN.png`;
const insertBelowImg = `${ASSETS_URL}/images/AI_Prompts/INSERT_BELOW.png`;
const insertAboveImg = `${ASSETS_URL}/images/AI_Prompts/INSERT_ABOVE.png`;
const replaceImg = `${ASSETS_URL}/images/AI_Prompts/REPLACE.png`;
const discardImg = `${ASSETS_URL}/images/AI_Prompts/DISCARD.png`;
const keyInfoImg = `${ASSETS_URL}/images/AI_Prompts/KEY_INFO.png`;
const histEventImg = `${ASSETS_URL}/images/AI_Prompts/HIST_EVENT.png`;
const formulaImg = `${ASSETS_URL}/images/AI_Prompts/FORMULA.png`;
const exampleImg = `${ASSETS_URL}/images/AI_Prompts/EXAMPLE.png`;
const continueWritingImg = `${ASSETS_URL}/images/AI_Prompts/CONTINUE_WRITING.png`;
const listImg = `${ASSETS_URL}/images/AI_Prompts/LIST.png`;
const askImg = `${ASSETS_URL}/images/AI_Prompts/ASK.png`;
const makeLongerImg = `${ASSETS_URL}/images/AI_Prompts/MAKE_LONGER.png`;
const makeShorterImg = `${ASSETS_URL}/images/AI_Prompts/MAKE_SMALLER.png`;
const simplifyLanguageImg = `${ASSETS_URL}/images/AI_Prompts/SIMPLIFY_LANGUAGE.png`;
const researchQuestionsImg = `${ASSETS_URL}/images/AI_Prompts/RESEARCH_QUESTIONS.png`;
const mnemonicsImg = `${ASSETS_URL}/images/AI_Prompts/MNEMONICS.png`;
const checkmarkImg = `${ASSETS_URL}/images/check-blue.png`;

export enum AiEditorMutationType {
    INSERT_BELOW = "INSERT_BELOW",
    INSERT_ABOVE = "INSERT_ABOVE",
    REPLACE = "REPLACE",
    DISCARD = "DISCARD",
    DONE = "DONE",
    ADD_FLASHCARDS = "ADD_FLASHCARDS",
}

export type AIPrompt = {
    name: string;
    type: AICompletionType | AiEditorMutationType;
    title: string;
    group: string;
    keywords: string;
    searchKeyword: string;
    image: string;
    prefill: string;
    placeholder?: string;
};

export const AI_PROMPTS: { [key in AICompletionType | AiEditorMutationType]?: AIPrompt } = {
    [AICompletionType.SIMPLE_FLASHCARD]: {
        name: "simple_flashcard",
        type: AICompletionType.SIMPLE_FLASHCARD,
        title: "Create flashcard",
        group: "Create with AI",
        keywords: "ai simple flashcard",
        searchKeyword: "fl",
        image: simpleFlashcardImg,
        prefill: "Create a flashcard for ",
    },
    [AICompletionType.FILL_BLANK_FLASHCARD]: {
        name: "fill_blank_flashcard",
        type: AICompletionType.FILL_BLANK_FLASHCARD,
        title: "Create fill-in-the blank flashcard",
        group: "Create with AI",
        keywords: "ai fill blank flashcard",
        searchKeyword: "fib",
        image: fillBlankImg,
        prefill: "Create a fill in the blank flashcard for ",
    },
    [AICompletionType.QUESTION_FLASHCARD]: {
        name: "question_flashcard",
        type: AICompletionType.QUESTION_FLASHCARD,
        title: "Create Q&A flashcard",
        group: "Create with AI",
        keywords: "ai question flashcard",
        searchKeyword: "qa",
        image: questionFlashcardImg,
        prefill: "Write a question and answer flashcard for ",
    },
    [AICompletionType.TRANSLATE_FLASHCARD]: {
        name: "translate_flashcard",
        type: AICompletionType.TRANSLATE_FLASHCARD,
        title: "Translate",
        group: "Translate with AI",
        keywords: "ai translate flashcard",
        searchKeyword: "t",
        image: translateImg,
        prefill: "Translate this: ",
    },
    [AICompletionType.CONTINUE_WRITING]: {
        name: "continue_writing",
        type: AICompletionType.CONTINUE_WRITING,
        title: "Continue Writing",
        group: "Write on",
        keywords: "ai continue writing",
        searchKeyword: "con",
        image: continueWritingImg,
        prefill: "Continue Writing: ",
    },
    [AICompletionType.LIST]: {
        name: "ailist",
        type: AICompletionType.LIST,
        title: "List",
        group: "Research with AI",
        keywords: "ai list",
        searchKeyword: "list",
        image: listImg,
        prefill: "Create a list on ",
    },
    [AICompletionType.KEY_INFO]: {
        name: "keyinfo",
        type: AICompletionType.KEY_INFO,
        title: "Key Information",
        group: "Research with AI",
        keywords: "ai key info",
        searchKeyword: "key",
        image: keyInfoImg,
        prefill: "Write out key information for ",
    },
    [AICompletionType.HIST_EVENT]: {
        name: "hist_event",
        type: AICompletionType.HIST_EVENT,
        title: "Historical Event",
        group: "Research with AI",
        keywords: "ai history event",
        searchKeyword: "eve",
        image: histEventImg,
        prefill: "Tell me everything about ",
    },
    [AICompletionType.EXAMPLE]: {
        name: "example",
        type: AICompletionType.EXAMPLE,
        title: "Example",
        group: "Research with AI",
        keywords: "ai example",
        searchKeyword: "ex",
        image: exampleImg,
        prefill: "Give an example for ",
    },
    [AICompletionType.FORMULA]: {
        name: "formula",
        type: AICompletionType.FORMULA,
        title: "Give me the formula",
        group: "Math with AI",
        keywords: "ai formula math",
        searchKeyword: "for",
        image: formulaImg,
        prefill: "Write out the formula for ",
    },
    [AICompletionType.GENERIC_FLASHCARD]: {
        name: "ai",
        type: AICompletionType.GENERIC_FLASHCARD,
        title: "Ask me anything...",
        group: "Generate",
        keywords: "ai ask write",
        searchKeyword: "ai",
        image: askImg,
        prefill: "Ask me anything about: ",
    },
    [AICompletionType.GENERIC_NOTE]: {
        name: "ai",
        type: AICompletionType.GENERIC_NOTE,
        title: "Ask me anything...",
        group: "Generate",
        keywords: "ai ask write",
        searchKeyword: "ai",
        image: askImg,
        prefill: "Ask me anything about: ",
    },
    [AICompletionType.MIND_MAP]: {
        name: "mind_map",
        type: AICompletionType.MIND_MAP,
        title: "Mind Map",
        group: "Draft with AI",
        keywords: "ai mind map",
        searchKeyword: "map",
        image: mindMapImg,
        prefill: "Create a mind map for ",
        placeholder: "Create a mind map on a specific topic…",
    },
    [AICompletionType.ESSAY_OUTLINE]: {
        name: "essay_outline",
        type: AICompletionType.ESSAY_OUTLINE,
        title: "Essay Outline",
        group: "Draft with AI",
        keywords: "ai essay outline",
        searchKeyword: "essay",
        image: mindMapImg,
        prefill: "Write an essay outline for ",
    },
    [AICompletionType.OUTLINE]: {
        name: "outline",
        type: AICompletionType.OUTLINE,
        title: "Outline",
        group: "Draft with AI",
        keywords: "ai outline",
        searchKeyword: "out",
        image: outlineImg,
        prefill: "Create an outline for ",
        placeholder: "Write an outline on any topic...",
    },
    [AICompletionType.BRAINSTORM]: {
        name: "brainstorm",
        type: AICompletionType.BRAINSTORM,
        title: "Brainstorm",
        group: "Draft with AI",
        keywords: "ai brainstorm",
        searchKeyword: "brain",
        image: brainstormImg,
        prefill: "Brainstorm ideas on: ",
    },
    [AICompletionType.DEBATE_ARGUMENT]: {
        name: "debate_argument",
        type: AICompletionType.DEBATE_ARGUMENT,
        title: "Debate Prep",
        group: "Draft with AI",
        keywords: "ai debate argument",
        searchKeyword: "deb",
        image: debateArgumentImg,
        prefill: "Construct a debate argument for ",
    },
    [AICompletionType.RESEARCH_QUESTIONS]: {
        name: "research_questions",
        type: AICompletionType.RESEARCH_QUESTIONS,
        title: "Research Questions",
        group: "Draft with AI",
        keywords: "ai research questions",
        searchKeyword: "res",
        image: researchQuestionsImg,
        prefill: "Write research questions for ",
        placeholder: "Research a specific topic for you…",
    },
    [AICompletionType.BOOK_SUM]: {
        name: "book_sum",
        type: AICompletionType.BOOK_SUM,
        title: "Book Summary",
        group: "Ask AI to Write",
        keywords: "ai book summary",
        searchKeyword: "book",
        image: bookSumImg,
        prefill: "Write notes on: ",
        placeholder: "Get notes on any book and chapter...",
    },
    [AICompletionType.CREATE_NOTE]: {
        name: "create_note",
        type: AICompletionType.CREATE_NOTE,
        title: "Write a note",
        group: "Ask AI to Write",
        keywords: "ai create note",
        searchKeyword: "note",
        image: createNoteImg,
        prefill: "Write notes on: ",
        placeholder: "Start writing notes on a specific topic…",
    },
    [AICompletionType.PROS_CONS]: {
        name: "pros_cons",
        type: AICompletionType.PROS_CONS,
        title: "Pros and Cons",
        group: "Ask AI to Write",
        keywords: "ai pros cons",
        searchKeyword: "pro",
        image: prosConsImg,
        prefill: "List the pros and cons of: ",
    },
    [AICompletionType.COVER_LETTER]: {
        name: "cover_letter",
        type: AICompletionType.COVER_LETTER,
        title: "Cover Letter",
        group: "Draft with AI",
        keywords: "ai cover letter",
        searchKeyword: "cover",
        image: coverLetterImg,
        prefill: '"Write a cover letter for this job description: ',
    },
    [AICompletionType.NEWSPAPER_ARTICLE]: {
        name: "newspaper_article",
        type: AICompletionType.NEWSPAPER_ARTICLE,
        title: "Newspaper Article",
        group: "Draft with AI",
        keywords: "ai newspaper article",
        searchKeyword: "news",
        image: newspaperArticleImg,
        prefill: "Write a newspaper article about ",
    },
    [AICompletionType.COLD_EMAIL]: {
        name: "cold_email",
        type: AICompletionType.COLD_EMAIL,
        title: "Cold Email",
        group: "Draft with AI",
        keywords: "ai cold email",
        searchKeyword: "email",
        image: coldEmailImg,
        prefill: "Write a cold email for ",
    },
    [AICompletionType.MNEMONICS]: {
        name: "mnemonics",
        type: AICompletionType.MNEMONICS,
        title: "Mnemonics",
        group: "Help me study",
        keywords: "ai mnemonics",
        searchKeyword: "mn",
        image: mnemonicsImg,
        prefill: "Make me a mnemonic on ",
    },
    [AICompletionType.KEY_VOCABULARY]: {
        name: "key_vocabulary",
        type: AICompletionType.KEY_VOCABULARY,
        title: "Key Vocabulary",
        group: "Help me study",
        keywords: "ai key vocabulary",
        searchKeyword: "vocab",
        image: keyVocabularyImg,
        prefill: "Write out key vocabulary for ",
    },
    [AICompletionType.SENTENCE_TO_FLASHCARD]: {
        name: "sentence_to_flashcard",
        type: AICompletionType.SENTENCE_TO_FLASHCARD,
        title: "Create flashcards",
        group: "Flashcards",
        keywords: "ai sentence to flashcard",
        searchKeyword: "fl",
        image: simpleFlashcardImg,
        prefill: "Sentence to Flashcard: ",
    },
    [AICompletionType.SENTENCE_TO_QCARD]: {
        name: "sentence_to_qcard",
        type: AICompletionType.SENTENCE_TO_QCARD,
        title: "Create Q&A flashcards",
        group: "Flashcards",
        keywords: "ai sentence to qcard",
        searchKeyword: "qa",
        image: questionFlashcardImg,
        prefill: "Sentence to Qcard: ",
    },
    [AICompletionType.SENTENCE_TO_FIBCARD]: {
        name: "sentence_to_fibcard",
        type: AICompletionType.SENTENCE_TO_FIBCARD,
        title: "Create fill-in-the-blank flashcards",
        group: "Flashcards",
        keywords: "ai sentence to fibcard",
        searchKeyword: "fib",
        image: fillBlankImg,
        prefill: "Sentence to Fibcard: ",
    },
    [AICompletionType.ELI5]: {
        name: "eli5",
        type: AICompletionType.ELI5,
        title: "Explain like I'm 5",
        group: "Create from selection",
        keywords: "ai eli5",
        searchKeyword: "eli5",
        image: eli5Img,
        prefill: "ELI5: ",
    },
    [AICompletionType.SUMMARIZE]: {
        name: "summarize",
        type: AICompletionType.SUMMARIZE,
        title: "Summarize",
        group: "Create from selection",
        keywords: "ai summarize",
        searchKeyword: "sum",
        image: summarizeImg,
        prefill: "Summarize: ",
    },
    [AICompletionType.TRANSLATE]: {
        name: "translate",
        type: AICompletionType.TRANSLATE,
        title: "Translate",
        group: "Create from selection",
        keywords: "ai translate",
        searchKeyword: "t",
        image: translateImg,
        prefill: "Translate: ",
    },
    [AICompletionType.CREATE_STUDY_PLAN]: {
        name: "create_study_plan",
        type: AICompletionType.CREATE_STUDY_PLAN,
        title: "Create Study Plan",
        group: "Create from selection",
        keywords: "ai create study plan",
        searchKeyword: "study",
        image: studyPlanImg,
        prefill: "Create Study Plan: ",
    },
    [AICompletionType.MAKE_LONGER]: {
        name: "make_longer",
        type: AICompletionType.MAKE_LONGER,
        title: "Make Longer",
        group: "Create from selection",
        keywords: "ai make longer",
        searchKeyword: "long",
        image: makeLongerImg,
        prefill: "Make Longer: ",
    },
    [AICompletionType.MAKE_SHORTER]: {
        name: "make_shorter",
        type: AICompletionType.MAKE_SHORTER,
        title: "Make Shorter",
        group: "Create from selection",
        keywords: "ai make shorter",
        searchKeyword: "short",
        image: makeShorterImg,
        prefill: "Make Shorter: ",
    },
    [AICompletionType.SIMPLIFY_LANGUAGE]: {
        name: "simplify_language",
        type: AICompletionType.SIMPLIFY_LANGUAGE,
        title: "Simplify Language",
        group: "Review or edit selection",
        keywords: "ai simplify language",
        searchKeyword: "simpl",
        image: simplifyLanguageImg,
        prefill: "Simplify Language: ",
    },
    [AICompletionType.FIX_SPELLING_GRAMMAR]: {
        name: "fix_spelling_grammar",
        type: AICompletionType.FIX_SPELLING_GRAMMAR,
        title: "Fix Spelling & Grammar",
        group: "Review or edit selection",
        keywords: "ai fix spelling grammar",
        searchKeyword: "spell",
        image: fixSpellingGrammarImg,
        prefill: "Fix Spelling & Grammar: ",
    },
    [AICompletionType.IMPROVE_WRITING]: {
        name: "improve_writing",
        type: AICompletionType.IMPROVE_WRITING,
        title: "Improve Writing",
        group: "Review or edit selection",
        keywords: "ai improve writing",
        searchKeyword: "improve",
        image: improveWritingImg,
        prefill: "Improve Writing: ",
    },
    [AICompletionType.ADD_MORE_DETAILS]: {
        name: "add_more_details",
        type: AICompletionType.ADD_MORE_DETAILS,
        title: "Add More Details",
        group: "Review or edit selection",
        keywords: "ai add more details",
        searchKeyword: "more",
        image: addMoreDetailsImg,
        prefill: "Add More Details: ",
    },
    [AICompletionType.FIND_QUOTES]: {
        name: "find_quotes",
        type: AICompletionType.FIND_QUOTES,
        title: "Find Quotes",
        group: "Create from selection",
        keywords: "ai find quotes",
        searchKeyword: "quote",
        image: findQuotesImg,
        prefill: "Find Quotes: ",
    },
    [AiEditorMutationType.ADD_FLASHCARDS]: {
        name: "editor_add_flashcards",
        type: AiEditorMutationType.ADD_FLASHCARDS,
        title: "Add flashcards to deck",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: simpleFlashcardImg,
        prefill: "",
    },
    [AiEditorMutationType.INSERT_BELOW]: {
        name: "editor_insert_below",
        type: AiEditorMutationType.INSERT_BELOW,
        title: "Insert Below",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: insertBelowImg,
        prefill: "",
    },
    [AiEditorMutationType.REPLACE]: {
        name: "editor_replace",
        type: AiEditorMutationType.REPLACE,
        title: "Replace Selection",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: replaceImg,
        prefill: "",
    },
    [AiEditorMutationType.INSERT_ABOVE]: {
        name: "editor_insert_above",
        type: AiEditorMutationType.INSERT_ABOVE,
        title: "Insert Above",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: insertAboveImg,
        prefill: "",
    },
    [AiEditorMutationType.DISCARD]: {
        name: "editor_discard",
        type: AiEditorMutationType.DISCARD,
        title: "Discard AI",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: discardImg,
        prefill: "",
    },
    [AiEditorMutationType.DONE]: {
        name: "editor_done",
        type: AiEditorMutationType.DONE,
        title: "Done",
        group: "Actions",
        keywords: "",
        searchKeyword: "",
        image: checkmarkImg,
        prefill: "",
    },
};

export const FLASHCARDS_EDITOR_CLASSNAME = "editFlashcardTextAreaInput";
