import React from "react";
import { NodeViewContent, NodeViewWrapper } from "@knowt/editor/react";

const CodeBlockComponent = ({
    node: {
        attrs: { language: defaultLanguage },
    },
    updateAttributes,
    extension,
}) => {
    return (
        <NodeViewWrapper style={{ position: "relative" }}>
            <select
                contentEditable={false}
                defaultValue={defaultLanguage}
                onChange={event => updateAttributes({ language: event.target.value })}
                style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.5rem",
                }}>
                <option value="null">auto</option>
                <option disabled>—</option>
                {extension.options.lowlight.listLanguages().map((lang, index) => (
                    <option key={index} value={lang}>
                        {lang}
                    </option>
                ))}
            </select>
            <pre>
                <NodeViewContent as="code" />
            </pre>
        </NodeViewWrapper>
    );
};

export default CodeBlockComponent;
