import { toast } from "react-hot-toast/headless";
import {
    callBatchUpdateFlashcard,
    callGetRawFlashcardSets,
    callListFlashcardSetsByClass,
    callListFlashcardSetsByFolder,
    callUpdateFlashcardSet,
    fetchFlashcardSet,
} from "@/hooks/flashcards/graphqlUtils";
import { platform } from "@/platform";
import { Flashcard, FlashcardSet, ItemType } from "@knowt/syncing/graphql/schema";
import { now } from "@/utils/SyncUtils";
import { fromEntries } from "@/utils/genericUtils";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useNote } from "../notes/useNotes";
import { useCurrentUser } from "../user/useCurrentUser";
import {
    addStandaloneFlashcardSetToTheCache,
    makeNewFlashcard,
    resolveFlashcardSetsSWRKey,
    resolveFlashcardSetSWRKey,
    stripBaseFlashcardSet,
    withHtmlFields,
} from "./utils";
import { filterClassItems } from "@/utils/course";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useNoteFlashcardSet = ({ noteId, autoCommit = true }) => {
    const { note, isLoading: isNoteLoading } = useNote({ noteId });

    return useFlashcardSet({
        flashcardSetId: note?.flashcardSetId,
        loadingFlashcardSetId: isNoteLoading,
        autoCommit,
    });
};

const useSavingHelpers = ({
    flashcardSet,
    loading,
    error,
}: {
    flashcardSet?: FlashcardSet | null;
    loading: boolean;
    error: Error;
}) => {
    const { userId } = useCurrentUser();

    const pendingUpdates = useRef<{ flashcardSet: FlashcardSet | null; flashcards: Record<string, Flashcard> | null }>({
        flashcardSet: null,
        flashcards: null,
    });

    const [lastSaved, setLastSaved] = useState<number>(null);
    if (!lastSaved && flashcardSet) setLastSaved(+flashcardSet.updated * 1000);

    const [isDirty, setIsDirty] = useState(false);

    const lastCommit = useRef<number>(null);

    const commitUpdates = useCallback(async () => {
        if (!userId) {
            setIsDirty(false);
            toast.error(
                "There was an error while saving your changes. Please refresh this page or your changes may not save."
            );
            const error = new Error("commitUpdates: userId is not available");
            const { report } = await platform.analytics.logging();
            report(error, "commitUpdates");
            throw error;
        }

        if (loading || error) {
            toast.error(
                "There was an error while saving your changes. Please refresh this page or your changes may not save."
            );
            const error = new Error("commitUpdates: cannot commit while loading or in error state");
            const { report } = await platform.analytics.logging();
            report(error, "commitUpdates");
            throw error;
        }

        const commitStartTime = Date.now();
        lastCommit.current = commitStartTime;

        const { flashcards: pendingUpdateFlashcards, flashcardSet: pendingUpdateFlashcardSet } = pendingUpdates.current;

        if (pendingUpdateFlashcards) {
            await callBatchUpdateFlashcard({
                userId,
                flashcardSetId: flashcardSet.flashcardSetId,
                items: Object.values(pendingUpdateFlashcards),
            });
        }

        if (pendingUpdateFlashcardSet) {
            await callUpdateFlashcardSet(
                flashcardSet.flashcardSetId,
                stripBaseFlashcardSet({
                    ...pendingUpdateFlashcardSet,
                    updated: now(),
                })
            );
        }

        // only set to null once the commit is done. if it errors, we want to keep the pending updates
        pendingUpdates.current = { flashcardSet: null, flashcards: null };
        setLastSaved(Date.now());

        const isLastCommit = lastCommit.current === commitStartTime;
        const noPendingUpdates = !pendingUpdates.current.flashcards && !pendingUpdates.current.flashcardSet;

        if (isLastCommit && noPendingUpdates) setIsDirty(false);
    }, [error, flashcardSet?.flashcardSetId, loading, userId]);

    const enqueueFlashcardUpdate = useCallback((flashcard: Flashcard) => {
        pendingUpdates.current.flashcards ||= {};
        pendingUpdates.current.flashcards[flashcard.flashcardId] = flashcard;
        setIsDirty(true);
    }, []);

    const enqueueFlashcardSetUpdate = useCallback((flashcardSet: FlashcardSet) => {
        pendingUpdates.current.flashcardSet = flashcardSet;
        setIsDirty(true);
    }, []);

    return { lastSaved, isDirty, commitUpdates, enqueueFlashcardUpdate, enqueueFlashcardSetUpdate };
};

export const useFlashcardSet = ({
    flashcardSetId,
    loadingFlashcardSetId = false,
    fallbackData = undefined,
    autoCommit = true,
    isEnabled = true,
}: {
    flashcardSetId?: string | null;
    loadingFlashcardSetId?: boolean;
    fallbackData?: FlashcardSet;
    autoCommit?: boolean;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    // eslint-disable-next-line react/hook-use-state
    const [computedOnceFallbackData] = useState(() => {
        if (fallbackData) {
            // we have to make sure the flashcards returned by `useFlashcardSet` have html fields
            fallbackData.flashcards = withHtmlFields({ flashcards: fallbackData.flashcards });
        }
        return fallbackData;
    });

    const {
        data: flashcardSet,
        mutate,
        error,
    } = useSWRImmutable(
        resolveFlashcardSetSWRKey({ userId, flashcardSetId, loadingFlashcardSetId, isEnabled }),
        async () => {
            if (!flashcardSetId) return null;

            const storage = await platform.storage();

            const password = (await storage.getWithExpiry(`${ItemType.FLASHCARDSET}-${flashcardSetId}-pwd`)) as
                | string
                | null;

            const flashcardSet = await fetchFlashcardSet({ flashcardSetId, password });

            if (!flashcardSet) {
                toast.error("Flashcard set not found");
                const error = new Error("Flashcard set not found");
                const { report } = await platform.analytics.logging();
                report(error, "useFlashcardSet fetcher");
                throw error;
            }

            // just in case the fields where in md format instead of html (old flashcard sets)
            flashcardSet.flashcards = withHtmlFields({ flashcards: flashcardSet.flashcards });

            return flashcardSet;
        },
        {
            fallbackData: computedOnceFallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const loading = typeof flashcardSet === "undefined" && !error;
    const flashcardSetAvailable = !!flashcardSet;

    const flashcards: Flashcard[] | null = useMemo(() => {
        if (!flashcardSet?.flashcards) return null;
        return flashcardSet.flashcards.filter(Boolean);
    }, [flashcardSet?.flashcards]);

    const { lastSaved, isDirty, commitUpdates, enqueueFlashcardUpdate, enqueueFlashcardSetUpdate } = useSavingHelpers({
        flashcardSet,
        loading,
        error,
    });

    const getCache = useCallback(async () => {
        return await mutate(current => current, { revalidate: false });
    }, [mutate]);

    const updateFlashcardSetCache = useCallback(
        async (
            _newFlashcardSetData: Partial<FlashcardSet> | ((oldFlashcardSet: FlashcardSet) => Partial<FlashcardSet>)
        ) => {
            if (loading || error || !flashcardSetAvailable) {
                toast.error(
                    "There was an error while saving your changes. Please refresh this page or your changes may not save."
                );

                const error = new Error(
                    "can only call `updateFlashcardSetCache` if the `flashcardSet` is available, and not loading or in error state."
                );

                const { report } = await platform.analytics.logging();
                report(error, "updateFlashcardSetCache");
                throw error;
            }

            const oldFlashcardSetCache = await mutate(current => current, { revalidate: false });

            const newFlashcardSetData =
                typeof _newFlashcardSetData === "function"
                    ? _newFlashcardSetData(oldFlashcardSetCache)
                    : _newFlashcardSetData;

            const updatedFlashcardSetCache = { ...oldFlashcardSetCache, ...newFlashcardSetData, updated: now() };
            await mutate(updatedFlashcardSetCache, { revalidate: false });

            const isStandaloneFlashcardSet = !updatedFlashcardSetCache?.noteId;
            if (isStandaloneFlashcardSet) await addStandaloneFlashcardSetToTheCache(updatedFlashcardSetCache);

            return { oldCache: oldFlashcardSetCache, updatedCache: updatedFlashcardSetCache };
        },
        [loading, error, flashcardSetAvailable, mutate]
    );

    const updateFlashcardSetMetaData = useCallback(
        async (updates: Parameters<typeof updateFlashcardSetCache>[0]) => {
            const { updatedCache } = await updateFlashcardSetCache(updates);
            enqueueFlashcardSetUpdate(updatedCache);
            if (autoCommit) await commitUpdates();
            return updatedCache;
        },
        [autoCommit, commitUpdates, enqueueFlashcardSetUpdate, updateFlashcardSetCache]
    );

    const updateFlashcardsCache = useCallback(
        (newFlashcards: Flashcard[] | ((oldFlashcards: Flashcard[]) => Flashcard[])) =>
            updateFlashcardSetCache(oldFlashcardSet => {
                if (typeof newFlashcards === "function") {
                    newFlashcards = newFlashcards(oldFlashcardSet?.flashcards ?? []);
                }
                return { flashcards: newFlashcards };
            }),
        [updateFlashcardSetCache]
    );

    const updateFlashcards = useCallback(
        async (newFlashcards: Flashcard[] | ((oldFlashcards: Flashcard[]) => Flashcard[])) => {
            const { oldCache, updatedCache } = await updateFlashcardsCache(newFlashcards);

            const updatedFlashcards = getUpdatedFlashcards(oldCache, updatedCache);
            updatedFlashcards.forEach(enqueueFlashcardUpdate);

            const addedFlashcards = getAddedFlashcards(oldCache, updatedCache);
            addedFlashcards.forEach(enqueueFlashcardUpdate);

            const deletedFlashcards = getDeletedFlashcards(oldCache, updatedCache);
            deletedFlashcards.forEach(card => enqueueFlashcardUpdate({ ...card, trash: true }));

            if (addedFlashcards.length || deletedFlashcards.length || isFlashcardsReorder(oldCache, updatedCache)) {
                enqueueFlashcardSetUpdate(updatedCache);
            }

            if (autoCommit) await commitUpdates();

            return updatedCache;
        },
        [updateFlashcardsCache, enqueueFlashcardUpdate, autoCommit, commitUpdates, enqueueFlashcardSetUpdate]
    );

    const isFlashcardsReorder = (oldCache: FlashcardSet, updatedCache: FlashcardSet) => {
        if (oldCache.flashcards.length !== updatedCache.flashcards.length) {
            return false;
        }

        return oldCache.flashcards.some((oldCard, i) => oldCard.flashcardId !== updatedCache.flashcards[i].flashcardId);
    };

    const getAddedFlashcards = (oldCache: FlashcardSet, updatedCache: FlashcardSet) => {
        const oldFlashcardsMap = fromEntries(oldCache.flashcards.map(f => [f.flashcardId, f]));
        return updatedCache.flashcards.filter(({ flashcardId }) => !oldFlashcardsMap[flashcardId]);
    };

    const getDeletedFlashcards = (oldCache: FlashcardSet, updatedCache: FlashcardSet) => {
        const updatedFlashcardsMap = fromEntries(updatedCache.flashcards.map(f => [f.flashcardId, f]));
        return oldCache.flashcards.filter(({ flashcardId }) => !updatedFlashcardsMap[flashcardId]);
    };

    const getUpdatedFlashcards = (oldCache: FlashcardSet, updatedCache: FlashcardSet) => {
        const isFlashcardUpdated = (newFlashcard: Flashcard, oldFlashcard: Flashcard) => {
            return Object.entries(newFlashcard).some(([key, value]) => oldFlashcard[key] !== value);
        };

        const oldFlashcardsMap = fromEntries(oldCache.flashcards.map(f => [f.flashcardId, f]));

        return updatedCache.flashcards
            .filter(({ flashcardId }) => oldFlashcardsMap[flashcardId])
            .filter(maybeUpdatedFlashcard => {
                const oldFlashcard = oldFlashcardsMap[maybeUpdatedFlashcard.flashcardId];
                return isFlashcardUpdated(maybeUpdatedFlashcard, oldFlashcard);
            });
    };

    const addFlashcard = useCallback(
        async ({ insertAt, initialFields }: { insertAt?: number; initialFields?: Partial<Flashcard> } = {}) => {
            const newFlashcard = makeNewFlashcard({ ...initialFields, edited: true, flashcardSetId, userId });

            const { updatedCache } = await updateFlashcardsCache(oldFlashcards => {
                const index = insertAt ?? oldFlashcards.length;
                return [...oldFlashcards.slice(0, index), newFlashcard, ...oldFlashcards.slice(index)];
            });

            enqueueFlashcardUpdate(newFlashcard);
            enqueueFlashcardSetUpdate(updatedCache);

            if (autoCommit) await commitUpdates();

            return newFlashcard;
        },
        [
            userId,
            flashcardSetId,
            updateFlashcardsCache,
            enqueueFlashcardUpdate,
            enqueueFlashcardSetUpdate,
            autoCommit,
            commitUpdates,
        ]
    );

    const addFlashcards = useCallback(
        async ({
            insertAt,
            flashcards,
            clearEmptyFlashcards,
        }: { insertAt?: number; flashcards?: Partial<Flashcard>[]; clearEmptyFlashcards?: boolean } = {}) => {
            const newFlashcards = flashcards.map(initialFields =>
                makeNewFlashcard({ ...initialFields, edited: true, flashcardSetId, userId })
            );

            const isContentEmpty = (content: string) => !content || content === "" || content === "<p></p>";

            const oldFlashcardsToDelete = [];

            const { updatedCache } = await updateFlashcardsCache(_oldFlashcards => {
                const oldFlashcards = clearEmptyFlashcards
                    ? _oldFlashcards.filter(f => {
                          if (!isContentEmpty(f.term) || !isContentEmpty(f.definition)) return true;
                          oldFlashcardsToDelete.push(f);
                      })
                    : _oldFlashcards;

                const index = insertAt ?? oldFlashcards.length;
                return [...oldFlashcards.slice(0, index), ...newFlashcards, ...oldFlashcards.slice(index)];
            });

            newFlashcards.map(flashcard => enqueueFlashcardUpdate(flashcard));
            oldFlashcardsToDelete.map(flashcard => enqueueFlashcardUpdate({ ...flashcard, trash: true }));
            enqueueFlashcardSetUpdate(updatedCache);

            if (autoCommit) await commitUpdates();

            return newFlashcards;
        },
        [
            updateFlashcardsCache,
            enqueueFlashcardSetUpdate,
            autoCommit,
            commitUpdates,
            flashcardSetId,
            userId,
            enqueueFlashcardUpdate,
        ]
    );

    const deleteFlashcard = useCallback(
        async (id: string) => {
            let cachedFlashcard: Flashcard | undefined;

            const { updatedCache } = await updateFlashcardsCache(oldFlashcards => {
                cachedFlashcard = oldFlashcards.find(({ flashcardId }) => flashcardId === id);
                return oldFlashcards.filter(({ flashcardId }) => flashcardId !== id);
            });

            if (!cachedFlashcard) {
                // the flashcard was already deleted
                return;
            }

            enqueueFlashcardUpdate({ ...cachedFlashcard, trash: true });
            enqueueFlashcardSetUpdate(updatedCache);

            if (autoCommit) await commitUpdates();

            return { ...cachedFlashcard, trash: true };
        },
        [updateFlashcardsCache, enqueueFlashcardUpdate, enqueueFlashcardSetUpdate, autoCommit, commitUpdates]
    );

    const updateFlashcard = useCallback(
        async (flashcardId: string, data: Partial<Flashcard> | ((oldFlashcard: Flashcard) => Partial<Flashcard>)) => {
            let updateAborted = false;

            const { updatedCache } = await updateFlashcardsCache(oldFlashcards => {
                const flashcardIndex = oldFlashcards.findIndex(({ flashcardId: _id }) => _id === flashcardId);

                if (flashcardIndex === -1) {
                    // happens if the flashcard we're trying to update has been deleted
                    // (for instance if the update was queued before the deletion)
                    updateAborted = true;
                    return oldFlashcards;
                }

                const oldFlashcard = oldFlashcards[flashcardIndex];
                if (typeof data === "function") {
                    data = data(oldFlashcard);
                }

                if (data.term || data.definition || data.image) {
                    data.edited = true;
                    data.updated = String(now());
                }

                const newFlashcards = [...oldFlashcards];
                newFlashcards[flashcardIndex] = { ...oldFlashcard, ...data };

                return newFlashcards;
            });

            if (updateAborted) {
                return null;
            }

            const updatedFlashcard = updatedCache.flashcards.find(f => f.flashcardId === flashcardId);

            enqueueFlashcardUpdate(updatedFlashcard);
            if (autoCommit) await commitUpdates();

            return updatedFlashcard;
        },
        [autoCommit, commitUpdates, enqueueFlashcardUpdate, updateFlashcardsCache]
    );

    return {
        flashcardSet,
        mutate,
        error,
        loading,
        link: `/flashcards/${flashcardSetId}`,
        getCache,
        flashcards,
        addFlashcard,
        addFlashcards,
        deleteFlashcard,
        updateFlashcards,
        updateFlashcard,
        updateFlashcardSetMetaData,
        commitUpdates,
        isDirty,
        lastSaved,
    };
};

export type UseFlashcardSet = ReturnType<typeof useFlashcardSet>;

export const useFlashcardSets = (flashcardSetIds: string[], isEnabled = true) => {
    const { data: flashcardSets } = useSWRImmutable(
        isEnabled && flashcardSetIds && ["getFlashcardSets", flashcardSetIds],
        async ([_, flashcardSetIds]) => callGetRawFlashcardSets(flashcardSetIds)
    );

    return { flashcardSets };
};

export const useFolderFlashcardSets = ({
    folderId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    folderId: string;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, FlashcardSet>;
}) => {
    const { userId } = useCurrentUser();

    const { data, error } = useSWRImmutable(
        resolveFlashcardSetsSWRKey({ userId, folderId, isEnabled }),
        () => callListFlashcardSetsByFolder({ folderId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const folderFlashcardSets = useMemo(() => {
        if (!folderId || !data) return null;

        return fromEntries(
            Object.entries(data).filter(
                // we add the folderId check because the set might have been moved.
                ([, flashcardSet]) => flashcardSet.trash === inTrash && flashcardSet.folderId === folderId
            )
        );
    }, [data, folderId, inTrash]);

    return { folderFlashcardSets, isLoading: !data && !error };
};

export const useClassFlashcardSets = ({
    classId,
    sectionId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    classId: string;
    sectionId?: string | null | undefined;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, FlashcardSet>;
}) => {
    const { userId, user } = useCurrentUser();

    const { data, error } = useSWRImmutable(
        resolveFlashcardSetsSWRKey({ userId, classId, isEnabled }),
        async () => callListFlashcardSetsByClass({ classId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const classFlashcardSets = useMemo(() => {
        if (!classId || !data) return {};

        return fromEntries(filterClassItems({ user, classId, items: data, inTrash, sectionId }));
    }, [data, classId, inTrash, sectionId, user]);

    return { classFlashcardSets, isLoading: !data && !error };
};
