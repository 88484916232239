import { Editor, Extension, generateText, getTextSerializersFromSchema } from "@knowt/editor/react";

export type AiInputMenuTriggerOptions = {
    openRef: { current: () => void };
    canShow: ({ editor }: { editor: Editor }) => boolean;
};

export const AiInputMenuTrigger = new Extension<AiInputMenuTriggerOptions>({
    name: "ai-menu-trigger",
    addOptions() {
        return {
            canShow: ({ editor: _editor }) => true,
        };
    },
    addKeyboardShortcuts() {
        return {
            Space: () => {
                const { from, to, $from } = this.editor.view.state.selection;

                const nodeContent = generateText($from.node().toJSON(), this.editor.extensionManager.extensions, {
                    textSerializers: getTextSerializersFromSchema(this.editor.schema),
                });

                if (from !== to || nodeContent) {
                    return false;
                }

                if (this.options.canShow({ editor: this.editor })) {
                    this.options.openRef.current();
                    return true;
                }

                return false;
            },
        };
    },
});
