import { CSSProperties } from "react";
import katex from "katex";
import clsx from "clsx";
import { themeColors } from "@/utils/themeColors";
import { MATHEMATICS_REGEX } from "@knowt/editor/extensions/mathematics";
import ReactHtmlParser from "react-html-parser";
import {
    swapLineHeightAttributePreProcessor,
    hideCollapsedHeadersContentPreProcessor,
    removeFontFamilyAttributePreProcessor,
} from "./preprocessors";

import "@/components/FullPageEditor/richEditor.css";
import "katex/dist/katex.min.css";

const renderLatexToString = (inputString: string) => {
    try {
        // `katex.renderToString` can throw if its input is invalid, like a string starting with `#`
        return inputString.replace(MATHEMATICS_REGEX, (_, latexContent) => katex.renderToString(latexContent));
    } catch {
        return inputString;
    }
};

type HtmlTextProps = {
    text?: string | null;
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
    hideCollapsedHeadersContent?: boolean;
};

const HtmlText = ({ text, style, onClick, className, hideCollapsedHeadersContent }: HtmlTextProps) => {
    return (
        <div
            className={clsx("ProseMirror", className)}
            style={{
                background: "transparent",
                color: themeColors.neutralBlack,
                fontFamily: "var(--knowt-font-name)",
                // NOTE: make sure `fit-content` does not break any UI. if you found any UI text broken, this probably it. Please remove if yes
                height: "fit-content",
                ...style,
            }}
            onClick={onClick}>
            {ReactHtmlParser(renderLatexToString(text ?? ""), {
                preprocessNodes: nodes => {
                    if (style?.lineHeight) {
                        swapLineHeightAttributePreProcessor(nodes, style.lineHeight);
                    }
                    removeFontFamilyAttributePreProcessor(nodes);
                    if (hideCollapsedHeadersContent) hideCollapsedHeadersContentPreProcessor(nodes);
                    return nodes;
                },
            })}
        </div>
    );
};

export default HtmlText;
