import { useEffect, useState } from "react";

export const useMounted = (delay = 0) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (delay > 0) {
            const timeout = setTimeout(() => {
                setMounted(true);
            }, delay);

            return () => clearTimeout(timeout);
        } else {
            setMounted(true);
        }
    }, []);

    return mounted;
};
