import Image from "next/image";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import clsx from "clsx";
import styles from "./emojiMenu.module.css";

const EmojiMenu = (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = index => {
        const item = props.items[index];

        if (item) {
            props.command({ name: item.name });
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useEffect(() => {
        setSelectedIndex(0);
    }, [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: x => {
            if (x.event.key === "ArrowUp") {
                upHandler();
                return true;
            }

            if (x.event.key === "ArrowDown") {
                downHandler();
                return true;
            }

            if (x.event.key === "Enter") {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    return (
        <div className={styles.items}>
            {props.items.map((item, index) => (
                <button
                    key={item.name}
                    className={clsx(styles.item, index === selectedIndex && styles.isSelected)}
                    onClick={() => selectItem(index)}>
                    {item.fallbackImage ? (
                        <Image src={item.fallbackImage} alt={item.name} height={10} width={10} />
                    ) : (
                        item.emoji
                    )}
                    :{item.name}:
                </button>
            ))}
        </div>
    );
};

export default forwardRef(EmojiMenu);
