import {
    findTable,
    getCellsInColumn,
    getCellsInRow,
    selectColumn,
    selectRow,
    selectTable,
} from "@knowt/editor/extensions/table";
import { Plugin } from "@knowt/editor/pm/state";
import { Decoration, DecorationSet } from "@knowt/editor/pm/view";
import { Editor } from "@knowt/editor/core";

const createControl = (className: string, onClick: (event: MouseEvent, el: HTMLElement) => void) => {
    const el = document.createElement("div");
    el.classList.add(className);
    el.addEventListener("mousedown", event => {
        event.preventDefault();
        event.stopImmediatePropagation();

        onClick(event, el);
    });
    return el;
};

export const tablePlugin = (editor: Editor) => {
    return new Plugin({
        props: {
            decorations(state) {
                if (!editor.isFocused) return DecorationSet.empty;

                const geWholeTableControlsDecorations = transaction => {
                    const table = findTable(transaction.selection);
                    if (!table) return [];

                    return [
                        Decoration.widget(table.pos + 1, () =>
                            createControl("table-controls", () => editor.view.dispatch(selectTable(transaction)))
                        ),
                    ];
                };

                const getColumnsControlsDecorations = transaction => {
                    const headerCells = getCellsInRow(0)(transaction.selection);
                    if (!headerCells) return [];

                    return headerCells.map(({ pos }, columnIndex) =>
                        Decoration.widget(pos + 1, () =>
                            createControl("table-column-controls", () =>
                                editor.view.dispatch(selectColumn(columnIndex)(transaction))
                            )
                        )
                    );
                };

                const getRowsControlsDecorations = transaction => {
                    const firstColumnCells = getCellsInColumn(0)(transaction.selection);
                    if (!firstColumnCells) return [];

                    return firstColumnCells.map(({ pos }, rowIndex) =>
                        Decoration.widget(pos + 1, () =>
                            createControl("table-row-controls", () =>
                                editor.view.dispatch(selectRow(rowIndex)(transaction))
                            )
                        )
                    );
                };

                return DecorationSet.create(state.tr.doc, [
                    ...geWholeTableControlsDecorations(state.tr),
                    ...getRowsControlsDecorations(state.tr),
                    ...getColumnsControlsDecorations(state.tr),
                ]);
            },
        },
    });
};
