export const fetchFileBlob = async (file: File) => {
    try {
        return await new Promise<Blob>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                if (event.target === null || event.target.result === null) {
                    reject(event);
                } else {
                    resolve(new Blob([event.target.result], { type: file.type }));
                }
            };
            reader.onerror = function (event) {
                reject(event);
            };
            reader.readAsArrayBuffer(file);
        });
    } catch (e) {
        throw new Error(JSON.stringify(e));
    }
};

export const getImgDimensions = async (url: string) => {
    const img = new Image();
    img.src = url;
    await img.decode();
    return { width: img.naturalWidth, height: img.naturalHeight };
};
