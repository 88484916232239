import { Extension } from "@knowt/editor/react";
import { Plugin } from "@knowt/editor/pm/state";
import { Decoration, DecorationSet } from "@knowt/editor/pm/view";

export const NON_PERSISTENT_HIGHLIGHT_META_KEY = "nonPersistentHighlight";

export const NonPersistentHighlight = Extension.create({
    name: "nonPersistentHighlight",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                state: {
                    init() {
                        return DecorationSet.empty;
                    },
                    apply(tr, set) {
                        const meta = tr.getMeta(NON_PERSISTENT_HIGHLIGHT_META_KEY);
                        if (!meta) return set.map(tr.mapping, tr.doc);

                        if (meta.type === "add") {
                            const { from, to, color } = meta;

                            const decoration = Decoration.inline(
                                from,
                                to,
                                { style: `background-color: ${color};` },
                                { color }
                            );

                            set = set.add(tr.doc, [decoration]);
                        }

                        if (meta.type === "remove") {
                            const { from, to, color } = meta;
                            set = set.remove(set.find(from, to, spec => spec.color === color));
                        }

                        return set;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});
