import { Plugin } from "@knowt/editor/pm/state";
import { Node } from "@knowt/editor/pm/model";
import { Decoration, DecorationSet } from "@knowt/editor/pm/view";
import { Editor } from "@knowt/editor/core";

const getAnchors = (editor: Editor, doc: Node, name: string) => {
    const decorations: Decoration[] = [];

    doc.descendants((node, pos) => {
        if (node.type.name !== name) return;

        // we're disabling collapsing inside tables since our collapsing logic
        // doesn't work well with tables at time of writing.
        if (editor.isActive("table")) return;

        decorations.push(
            Decoration.widget(
                pos + 1,
                () => {
                    const collapse = document.createElement("button");

                    collapse.classList.add("strippedBtn", "heading-collapse");

                    const collapsedSVG = `<svg viewBox="0 0 24 24" width="18" height="18" stroke="none" fill="currentColor" class="css-i6dzq1"><path d="M9 19l7-7-7-7"></path></svg>`;
                    const nonCollapsedSVG = `<svg viewBox="0 0 24 24" width="18" height="18" stroke="none" fill="currentColor" class="css-i6dzq1"><path d="M19 9l-7 7-7-7"></path></svg>`;
                    collapse.innerHTML = node.attrs.collapsed ? collapsedSVG : nonCollapsedSVG;

                    collapse.addEventListener("mousedown", event => {
                        event.preventDefault();
                        event.stopPropagation();

                        editor.commands.command(({ tr }) => {
                            tr.setNodeMarkup(pos, undefined, {
                                ...node.attrs,
                                collapsed: !node.attrs.collapsed,
                            });

                            return true;
                        });
                    });

                    return collapse;
                },
                {
                    side: -1,
                }
            )
        );
    });

    return DecorationSet.create(doc, decorations);
};

export const headingCollapseControlPlugin = (editor: Editor, name: string) => {
    return new Plugin({
        state: {
            init: (_, state) => {
                return getAnchors(editor, state.doc, name);
            },
            apply: (tr, oldState) => {
                return tr.docChanged ? getAnchors(editor, tr.doc, name) : oldState;
            },
        },
        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });
};
