import { RefObject, useEffect, useRef } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

const useResizeObserver = (
    ref: RefObject<HTMLElement>,
    callback: (rect: DOMRectReadOnly, isInitialRender: boolean) => void
) => {
    const isInitialRender = useRef(true);
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useIsomorphicLayoutEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (!Array.isArray(entries)) return;
            callbackRef.current(entries[0].contentRect, isInitialRender.current);
            if (isInitialRender.current) isInitialRender.current = false;
        });

        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();
    }, [ref]);
};

export default useResizeObserver;
