import { useEffect, useRef, useState } from "react";
import { NodeViewWrapper } from "@knowt/editor/react";
import { NodeSelection } from "@knowt/editor/pm/state";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";
import styled from "styled-components";
import useResizeObserver from "@/utils/useResizeObserver";

const useIsSafari = () => {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        if (navigator.userAgent.match(/OS X.*Safari/) && !navigator.userAgent.match(/Chrome/)) {
            setIsSafari(true);
        }
    }, []);

    return isSafari;
};

const ImageComponent = ({ selected, node, editor, getPos, updateAttributes }) => {
    const { alt, src, title, width, height } = node.attrs;

    const resizableWrapperRef = useRef(null);
    const isSafari = useIsSafari();

    const isUserResizing = useRef(false);

    const debouncedUpdateAttributes = useDebouncedCallback(updateAttributes, 100);

    useResizeObserver(resizableWrapperRef, (rect, isInitialRender) => {
        if (editor.isEditable && !isInitialRender && isUserResizing.current) {
            debouncedUpdateAttributes({ width: rect.width, height: rect.height });
        }
    });

    const selectNode = () => {
        const { view } = editor;
        const $pos = view.state.doc.resolve(getPos());
        const transaction = view.state.tr.setSelection(new NodeSelection($pos));
        view.dispatch(transaction);
    };

    return (
        <NodeViewWrapper>
            <div
                contentEditable={false}
                style={{ textAlign: "center", maxWidth: "100%", clear: "both" }}
                onClick={() => selectNode()}
                role="button">
                <ImageWrapper style={{ outline: selected ? "1px solid #68cef8" : undefined }}>
                    <ResizableWrapper
                        onPointerDown={() => (isUserResizing.current = true)}
                        onPointerUp={() => (isUserResizing.current = false)}
                        ref={resizableWrapperRef}
                        {...{ width, height }}>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            width={width}
                            height={height}
                            src={src}
                            alt={alt}
                            title={title}
                            style={{
                                pointerEvents: editor.isEditable ? "none" : "initial",
                                display: "inline-block",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                        {/** Safari has a bug where the icon prevents resizing */}
                        {!isSafari && (
                            <ResizeButtonContainer>
                                <ResizeIconContainer>{resizeIcon}</ResizeIconContainer>
                            </ResizeButtonContainer>
                        )}
                    </ResizableWrapper>
                </ImageWrapper>
            </div>
        </NodeViewWrapper>
    );
};

export default ImageComponent;

const resizeIcon = (
    <svg viewBox="0 0 512 512" style={{ transform: "rotate(-90deg)" }}>
        <path
            fill="#FFF"
            d="M208 281.4c-12.5-12.5-32.76-12.5-45.26-.002l-78.06 78.07l-30.06-30.06c-6.125-6.125-14.31-9.367-22.63-9.367c-4.125 0-8.279 .7891-12.25 2.43c-11.97 4.953-19.75 16.62-19.75 29.56v135.1C.0013 501.3 10.75 512 24 512h136c12.94 0 24.63-7.797 29.56-19.75c4.969-11.97 2.219-25.72-6.938-34.87l-30.06-30.06l78.06-78.07c12.5-12.49 12.5-32.75 .002-45.25L208 281.4zM487.1 0h-136c-12.94 0-24.63 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.87l30.06 30.06l-78.06 78.07c-12.5 12.5-12.5 32.76 0 45.26l22.62 22.62c12.5 12.5 32.76 12.5 45.26 0l78.06-78.07l30.06 30.06c9.156 9.141 22.87 11.84 34.87 6.937C504.2 184.6 512 172.9 512 159.1V23.1C512 10.74 501.3 0 487.1 0z"
        />
    </svg>
);

const ResizableWrapper = styled.div.attrs<{ width: number; height: number }>(({ width, height }) => ({
    style: {
        aspectRatio: `${width} / ${height}`,
        width: `${width}px`,
    },
}))`
    resize: both;
    overflow: hidden;
    position: relative;
    height: auto !important;
    max-width: 100%;

    &::-webkit-resizer {
        display: none;
    }

    @media (max-width: 600px) {
        max-width: 300px;
    }
`;

const Button = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    border: 0;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    background: ${props => props.theme.background};
    color: ${props => props.theme.textSecondary};
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
    opacity: 0;
    transition: opacity 100ms ease-in-out;

    &:active {
        transform: scale(0.98);
    }

    &:hover {
        color: ${props => props.theme.text};
        opacity: 1;
    }
`;

const ImageWrapper = styled.span`
    line-height: 0;
    display: inline-block;
    position: relative;
    max-width: 100%;
    padding: 1px;

    &:hover {
        ${Button} {
            opacity: 0.9;
        }
    }
`;

const ResizeButtonContainer = styled.div`
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
`;

const ResizeIconContainer = styled.div`
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
    margin-top: 5px;
    margin-left: 5px;
`;
