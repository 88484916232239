import Menu, { menuClasses } from "@mui/material/Menu";
import { COLORS } from "../constants";
import { Button } from "./DropdownButtons";
import styles from "./colorAndHighlightMenu.module.css";
import { ColorAndHighlightMenuProps } from "./types";
import { spacing } from "@/utils/spacing";
import { FlexColumn } from "@/components/Flex";

const ColorMenu = ({ editor, isOpen, anchorEl, onClose }: ColorAndHighlightMenuProps) => {
    return (
        <Menu
            open={isOpen}
            anchorReference={"anchorPosition"}
            anchorPosition={{
                left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
                top: anchorEl ? anchorEl.getBoundingClientRect().top + 30 : 0,
            }}
            onClose={() => {
                editor.commands.focus();
                onClose();
            }}
            sx={{
                [`.${menuClasses.paper}`]: {
                    padding: 0,
                    boxShadow: "rgb(0 0 0 / 8%) 0 0.4rem 1.6rem 0",
                },
                [`.${menuClasses.list}`]: {
                    backgroundColor: "var(--color-neutral-white)",
                },
            }}>
            <FlexColumn style={{ rowGap: spacing.XS }}>
                <div className={styles.title}>Colors</div>
                <Button
                    label={"Default"}
                    isActive={!editor?.getAttributes("textStyle").color}
                    onClick={() => {
                        editor.chain().focus().unsetColor().run();
                        onClose();
                    }}
                />
                {Object.values(COLORS).map(color => (
                    <Button
                        key={color.label}
                        isActive={color.value === editor?.getAttributes("textStyle").color}
                        label={color.label}
                        fg={color.cssVar}
                        onClick={() => {
                            editor.chain().focus().setColor(color.value).run();
                            onClose();
                        }}
                    />
                ))}
            </FlexColumn>
        </Menu>
    );
};

export default ColorMenu;
