import { lowlight } from "lowlight";

import { CodeBlockLowlight as BaseCodeBlockLowlight } from "@tiptap/extension-code-block-lowlight";

export const CodeBlockLowlight = BaseCodeBlockLowlight.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            lowlight,
        };
    },
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            Tab: () => {
                if (this.editor.isActive("codeBlock")) {
                    this.editor.commands.insertContent("\t");
                    return true;
                }

                return false;
            },
            "Shift-Tab": () => {
                if (this.editor.isActive("codeBlock")) {
                    // TODO: outdent
                    return false;
                }

                return false;
            },
            "Mod-a": () => {
                if (this.editor.isActive("codeBlock")) {
                    // TODO: only select the content of the code block
                    return false;
                }

                return false;
            },
        };
    },
});
