import { useRef, useState } from "react";
import {
    BoldIcon,
    CodeIcon,
    Heading1Icon,
    Heading2Icon,
    Heading3Icon,
    ItalicIcon,
    ListIcon,
    ListOrderedIcon,
    ListTodoIcon,
    SparklesIcon,
    StrikethroughIcon,
    QuoteIcon,
    UnderlineIcon,
    TextIcon,
    Link2Icon,
    Heading4Icon,
    BaselineIcon,
    HighlighterIcon,
    Trash2Icon,
    ArrowLeftFromLineIcon,
    ArrowRightFromLineIcon,
    ArrowUpFromLineIcon,
    ArrowDownFromLineIcon,
} from "lucide-react";
import styled from "styled-components";
import { Editor } from "@knowt/editor/react";
import CommandItem, { Item } from "./CommandItem";
import ColorMenu from "./ColorMenu";
import HighlightMenu from "./HighlightMenu";
import { iconSizes } from "@/utils/iconProps";
import { getSelectionType } from "./utils";
import { spacing } from "@/utils/spacing";

type Separator = { name: "separator" };

const isSeparator = (item: Item | Separator): item is Separator => {
    return item.name === "separator";
};

type CommandItemsProps = {
    editor: Editor;
    openLinkEditor: () => void;
    handleOpenAiInputMenu: () => void;
};

const CommandItems = ({ editor, openLinkEditor, handleOpenAiInputMenu }: CommandItemsProps) => {
    const [isColorMenuOpen, setIsColorMenuOpen] = useState(false);
    const [isHighlightMenuOpen, setIsHighlightMenuOpen] = useState(false);

    const colorMenuRef = useRef<HTMLDivElement>(null);
    const highlightMenuRef = useRef<HTMLDivElement>(null);

    const textSelectionItems: (Item | Separator)[] = [
        {
            name: "AI",
            tooltip: "Use AI",
            isActive: () => false,
            isDisabled: () => false,
            command: () => handleOpenAiInputMenu(),
            icon: props => <SparklesIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "separator",
        },
        {
            name: "bold",
            tooltip: "Bold",
            isActive: () => editor.isActive("bold"),
            isDisabled: () => !editor.can().toggleBold(),
            command: () => editor.chain().focus().toggleBold().run(),
            icon: props => <BoldIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "underline",
            tooltip: "Underline",
            isActive: () => editor.isActive("underline"),
            isDisabled: () => !editor.can().toggleUnderline(),
            command: () => editor.chain().focus().toggleUnderline().run(),
            icon: props => <UnderlineIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "italic",
            tooltip: "Italic",
            isActive: () => editor.isActive("italic"),
            isDisabled: () => !editor.can().toggleItalic(),
            command: () => editor.chain().focus().toggleItalic().run(),
            icon: props => <ItalicIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "strike",
            tooltip: "Strikethrough",
            isActive: () => editor.isActive("strike"),
            isDisabled: () => !editor.can().toggleStrike(),
            command: () => editor.chain().focus().toggleStrike().run(),
            icon: props => <StrikethroughIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "code",
            tooltip: "Code",
            isActive: () => editor.isActive("code"),
            isDisabled: () => !editor.can().toggleCode(),
            command: () => editor.chain().focus().toggleCode().run(),
            icon: props => <CodeIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "separator",
        },
        {
            name: "color",
            tooltip: "Text Color",
            isActive: () => !!editor.getAttributes("textStyle").color,
            isDisabled: () => !editor.can().setMark("textStyle"),
            command: () => setIsColorMenuOpen(true),
            icon: props => (
                <div ref={colorMenuRef}>
                    <BaselineIcon size={iconSizes.SM} {...props} />
                </div>
            ),
        },
        {
            name: "highlight",
            tooltip: "Highlight",
            isActive: () => editor.isActive("highlight"),
            isDisabled: () => !editor.can().toggleHighlight(),
            command: () => setIsHighlightMenuOpen(true),
            icon: props => (
                <div ref={highlightMenuRef}>
                    <HighlighterIcon size={iconSizes.SM} {...props} />
                </div>
            ),
        },
        {
            name: "separator",
        },
        {
            name: "text",
            tooltip: "Normal text",
            isActive: () => editor.isActive("paragraph"),
            isDisabled: () => !editor.can().setParagraph(),
            command: () => editor.chain().focus().setParagraph().run(),
            icon: props => <TextIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "heading 1",
            tooltip: "Heading 1",
            isActive: () => editor.isActive("heading", { level: 1 }),
            isDisabled: () => !editor.can().toggleHeading({ level: 1 }),
            command: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
            icon: props => <Heading1Icon size={iconSizes.SM} {...props} />,
        },
        {
            name: "heading 2",
            tooltip: "Heading 2",
            isActive: () => editor.isActive("heading", { level: 2 }),
            isDisabled: () => !editor.can().toggleHeading({ level: 2 }),
            command: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
            icon: props => <Heading2Icon size={iconSizes.SM} {...props} />,
        },
        {
            name: "heading 3",
            tooltip: "Heading 3",
            isActive: () => editor.isActive("heading", { level: 3 }),
            isDisabled: () => !editor.can().toggleHeading({ level: 3 }),
            command: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
            icon: props => <Heading3Icon size={iconSizes.SM} {...props} />,
        },
        {
            name: "heading 4",
            tooltip: "Heading 4",
            isActive: () => editor.isActive("heading", { level: 4 }),
            isDisabled: () => !editor.can().toggleHeading({ level: 4 }),
            command: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
            icon: props => <Heading4Icon size={iconSizes.SM} {...props} />,
        },
        {
            name: "separator",
        },
        {
            name: "todo list",
            tooltip: "Task list",
            isActive: () => editor.isActive("taskList"),
            isDisabled: () => !editor.can().toggleTaskList(),
            command: () => editor.chain().focus().toggleTaskList().run(),
            icon: props => <ListTodoIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "bullet list",
            tooltip: "Bullet list",
            isActive: () => editor.isActive("bulletList"),
            isDisabled: () => !editor.can().toggleBulletList(),
            command: () => editor.chain().focus().toggleBulletList().run(),
            icon: props => <ListIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "ordered list",
            tooltip: "Ordered list",
            isActive: () => editor.isActive("orderedList"),
            isDisabled: () => !editor.can().toggleOrderedList(),
            command: () => editor.chain().focus().toggleOrderedList().run(),
            icon: props => <ListOrderedIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "separator",
        },
        {
            name: "Quote",
            tooltip: "Quote",
            isActive: () => editor.isActive("blockquote"),
            isDisabled: () => !editor.can().toggleBlockquote(),
            command: () => editor.chain().focus().toggleBlockquote().run(),
            icon: props => <QuoteIcon size={iconSizes.SM} {...props} />,
        },
        {
            name: "link",
            tooltip: "Link",
            isActive: () => editor.isActive("link"),
            isDisabled: () => !editor.can().setLink({ href: "https://dummy.com" }),
            command: () => openLinkEditor(),
            icon: props => <Link2Icon size={iconSizes.SM} {...props} />,
        },
    ];

    const addColumnBeforeOption = {
        name: "add column before",
        tooltip: "Add column before",
        isActive: () => false,
        isDisabled: () => !editor.can().addColumnBefore(),
        command: () => editor.chain().focus().addColumnBefore().run(),
        icon: props => <ArrowLeftFromLineIcon size={iconSizes.SM} {...props} />,
    };

    const addColumnAfterOption = {
        name: "table column after",
        tooltip: "Add column after",
        isActive: () => false,
        isDisabled: () => !editor.can().addColumnAfter(),
        command: () => editor.chain().focus().addColumnAfter().run(),
        icon: props => <ArrowRightFromLineIcon size={iconSizes.SM} {...props} />,
    };

    const addRowBeforeOption = {
        name: "table row add above",
        tooltip: "Add row above",
        isActive: () => false,
        isDisabled: () => !editor.can().addRowBefore(),
        command: () => editor.chain().focus().addRowBefore().run(),
        icon: props => <ArrowUpFromLineIcon size={iconSizes.SM} {...props} />,
    };

    const addRowAfterOption = {
        name: "table row add below",
        tooltip: "Add row below",
        isActive: () => false,
        isDisabled: () => !editor.can().addRowAfter(),
        command: () => editor.chain().focus().addRowAfter().run(),
        icon: props => <ArrowDownFromLineIcon size={iconSizes.SM} {...props} />,
    };

    const tableColumnSelectionItems: (Item | Separator)[] = [
        addColumnBeforeOption,
        addColumnAfterOption,
        {
            name: "separator",
        },
        {
            name: "table column delete",
            tooltip: "Delete column",
            isActive: () => false,
            isDisabled: () => !editor.can().deleteColumn(),
            command: () => editor.chain().focus().deleteColumn().run(),
            icon: props => <Trash2Icon size={iconSizes.SM} {...props} />,
        },
    ];

    const tableRowSelectionItems: (Item | Separator)[] = [
        addRowBeforeOption,
        addRowAfterOption,
        {
            name: "separator",
        },
        {
            name: "table row delete",
            tooltip: "Delete row",
            isActive: () => false,
            isDisabled: () => !editor.can().deleteRow(),
            command: () => editor.chain().focus().deleteRow().run(),
            icon: props => <Trash2Icon size={iconSizes.SM} {...props} />,
        },
    ];

    const tableSelectionItems: (Item | Separator)[] = [
        addRowBeforeOption,
        addRowAfterOption,
        {
            name: "separator",
        },
        addColumnBeforeOption,
        addColumnAfterOption,
        {
            name: "separator",
        },
        {
            name: "table delete",
            tooltip: "Delete table",
            isActive: () => false,
            isDisabled: () => !editor.can().deleteTable(),
            command: () => editor.chain().focus().deleteTable().run(),
            icon: props => <Trash2Icon size={iconSizes.SM} {...props} />,
        },
    ];

    const selectionType = getSelectionType(editor.state.selection);

    const items =
        selectionType === "text"
            ? textSelectionItems
            : selectionType === "table-all"
            ? tableSelectionItems
            : selectionType === "table-column"
            ? tableColumnSelectionItems
            : tableRowSelectionItems;

    return (
        <>
            <Container>
                {items.map((item, index) =>
                    isSeparator(item) ? (
                        <Separator key={`${index}${item.name}`} />
                    ) : (
                        <CommandItem key={`${index}${item.name}`} item={item} />
                    )
                )}
            </Container>
            <ColorMenu
                editor={editor}
                isOpen={isColorMenuOpen}
                anchorEl={colorMenuRef.current}
                onClose={() => setIsColorMenuOpen(false)}
            />
            <HighlightMenu
                editor={editor}
                isOpen={isHighlightMenuOpen}
                anchorEl={highlightMenuRef.current}
                onClose={() => setIsHighlightMenuOpen(false)}
            />
        </>
    );
};

export default CommandItems;

const Container = styled.div`
    border-radius: 4px;
    height: 35px;
    display: flex;
    align-items: center;
    padding-inline: ${spacing.XS};
    column-gap: ${spacing.XXS};
    background-color: var(--color-neutral-white);
    box-shadow: rgb(0 0 0 / 8%) 0 0.4rem 1.6rem 0;
`;

const Separator = styled.div`
    height: 65%;
    margin-block: auto;
    margin-inline: 4px;
    width: 2px;
    background: rgb(29, 30, 30);
    opacity: 0.2;
    display: inline-block;

    [data-theme="dark"] & {
        background: rgb(255, 255, 255);
    }
`;
