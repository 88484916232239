import { Color as BaseColor } from "@tiptap/extension-color";

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        customColor: {
            /**
             * Set the font size
             */
            toggleColor: (size: string) => ReturnType;
        };
    }
}

export const Color = BaseColor.extend({
    addCommands() {
        return {
            ...this.parent?.(),
            toggleColor:
                color =>
                ({ commands, editor }) => {
                    const activeColor = editor.getAttributes("textStyle").color;
                    return activeColor === color ? commands.unsetColor() : commands.setColor(color);
                },
        };
    },
});
