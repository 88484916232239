import { Extension, generateText, getTextSerializersFromSchema } from "@tiptap/react";
import { DecorationSet, EditorView } from "@tiptap/pm/view";
import { createRoot, Root } from "react-dom/client";
import { Plugin, EditorState } from "@tiptap/pm/state";
import { assertTruthy } from "@knowt/syncing/utils/assertions";

type CustomPlaceholderProps = {
    placeholder: React.ReactElement;
};

type CustomPlaceholderStorage = {
    isMounted: boolean;
    root: Root | null;
};

export const CustomPlaceholder = new Extension<CustomPlaceholderProps, CustomPlaceholderStorage>({
    name: "custom-placeholder",
    addStorage() {
        return {
            isMounted: false,
            root: null as Root | null,
        };
    },
    addProseMirrorPlugins() {
        const shouldShowPlaceholder = (state: EditorState) => {
            const openedMenusSet = this.editor.storage.menusState?.openedMenusSet;
            if (openedMenusSet && openedMenusSet.size > 0) return false;

            if (this.editor.isFocused || state.doc.childCount > 1) {
                return false;
            }

            const firstChild = state.doc.firstChild;
            if (!firstChild) return true;

            if (firstChild.type.name !== "paragraph") {
                return false;
            }

            const firstChildContent = generateText(firstChild.toJSON(), this.editor.extensionManager.extensions, {
                textSerializers: getTextSerializersFromSchema(this.editor.schema),
            });

            if (firstChildContent) {
                return false;
            }

            return true;
        };

        const handleTemplatePlaceholderRender = (view: EditorView) => {
            assertTruthy(this.storage.root, "at this point, root should be non null");

            if (shouldShowPlaceholder(view.state)) {
                this.storage.root.render(this.options.placeholder);
            } else {
                this.storage.root.render("");
            }
        };

        return [
            new Plugin({
                view: initView => {
                    if (this.options.placeholder && initView.dom.parentElement && !this.storage.isMounted) {
                        if (!this.storage.root) {
                            const placeholder = document.createElement("div");
                            placeholder.className = "custom-placeholder";
                            this.storage.root = createRoot(placeholder);
                            initView.dom.parentElement.appendChild(placeholder);
                        }

                        this.storage.isMounted = true;

                        if (shouldShowPlaceholder(initView.state)) {
                            this.storage.root.render(this.options.placeholder);
                        }
                    }

                    return {
                        update: view => {
                            handleTemplatePlaceholderRender(view);
                        },
                        destroy: () => {
                            this.storage.isMounted = false;
                            if (this.storage.root) {
                                this.storage.root.unmount();
                                this.storage.root = null;
                            }
                        },
                    };
                },
                props: {
                    decorations: () => DecorationSet.empty,
                    handleDOMEvents: {
                        blur: view => {
                            handleTemplatePlaceholderRender(view);
                            return false;
                        },
                    },
                },
            }),
        ];
    },
});
